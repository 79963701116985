import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserService } from '../modules/configuracoes/usuario/Service';
import { Loader2, CheckCircle } from 'lucide-react';
import { useAppContext } from '../contexts/AppContext';

const service = new UserService();

export default function VerificationAccountWhatsapp() {
    const { errorSnackbar } = useAppContext();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const { uid, code } = useParams<{ uid: string; code: string }>();

    const hasVerified = useRef(false);

    const verifyAccount = async (codeParam: string, uid: string) => {
        if (loading || hasVerified.current) return;
        hasVerified.current = true;

        try {
            setLoading(true);
            await service.changePhoneNumber(codeParam, uid);
            setSuccess(true);
        } catch (error) {
            console.error(error);
            errorSnackbar('Falha ao verificar a conta. Código inválido ou expirado.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!!code && !!uid) {
            verifyAccount(code, uid);
        } else {
            navigate('/');
        }
    }, [code, uid, navigate]);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-4">
            <div className="bg-white shadow-lg rounded-2xl p-8 max-w-md text-center">
                {loading ? (
                    <>
                        <Loader2 className="w-12 h-12 text-blue-500 animate-spin mx-auto" />
                        <p className="text-gray-700 mt-4">Verificando sua conta do WhatsApp...</p>
                    </>
                ) : success ? (
                    <>
                        <CheckCircle className="w-16 h-16 text-green-500 mx-auto" />
                        <h2 className="text-2xl font-semibold text-gray-800 mt-4">Conta Confirmada!</h2>
                        <p className="text-gray-600 mt-2">
                            Seu número de WhatsApp foi verificado com sucesso.
                        </p>
                        <button className="btn btn-primary mt-5" type="submit" onClick={() => {
                            navigate('/')
                            window.location.reload()
                        }}>
                            Voltar para o app!
                        </button>
                    </>
                ) : (
                    <p className="text-red-500">Código inválido ou expirado.</p>
                )}
            </div>
        </div>
    );
}
