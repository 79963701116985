import { useEstabFormContext } from './Context';
import React from 'react';

export function GeralEstabelecimento() {
    const { name, setName } = useEstabFormContext();

    return (
        <>
            <div className="form-control mb-4">
                <label className="label" htmlFor="name">
                    <span className="label-text">Nome:</span>
                </label>
                <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="input input-bordered w-full max-w-96"
                />
            </div>
        </>
    );
}
