import { useEffect, useState } from 'react';
import { Modal } from '../../../components/Modal';
import { EstabelecimentosService } from '../system-admin/Service';
import { FilePreviewImage } from '../../../components/files/PreviewImage';
import { DurationService } from '../../servicos/list/Duration';
import { formatToReal } from '../../../components/utils';
import { baseURL, urlFile } from '../../../AxiosConfig';
import { ToggleAddEstabUserBtn } from '../client/AddEstabUser';
import { useEstabsContext } from '../../../contexts/EstabsContext';
import { Loading } from '../../../components/Loading';

const service = new EstabelecimentosService();

interface ServicosViewModalProps {
    estabId: string;
    onClose: () => void;
}

export const ViewEstabInfoModal = ({ estabId, onClose }: ServicosViewModalProps) => {
    const [loading, setLoading] = useState(true);
    const [estab, setEstab] = useState({} as any);
    const { estabsUser } = useEstabsContext();

    const loadServicos = async (estabId: string) => {
        try {
            setLoading(true);
            const { data } = await service.getInfoById(estabId);
            setEstab(data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadServicos(estabId);
    }, [estabId]);
    return (
        <Modal onClose={onClose} title="Estabelecimento">
            <div className="w-full">
                <div className="flex-1 flex flex-col items-center text-center space-y-3">
                    {estab?.imageId ? (
                        <div className="overflow-hidden">
                            <img src={`${urlFile}/${estab?.imageId}`} style={{ width: '120px' }} alt="Logo" />
                        </div>
                    ) : <h2 className="card-title flex items-center gap-2 text-lg font-semibold">
                        {estab?.name}
                    </h2>}

                    <p>{estab?.description}</p>

                    <p className="text-xs">
                        {[
                            estab?.address?.street,
                            estab?.address?.number,
                            estab?.address?.complement,
                            estab?.address?.neighborhood,
                            estab?.address?.city,
                            estab?.address?.state,
                            estab?.address?.zipCode,
                        ]
                            .filter(Boolean)
                            .join(', ')}
                    </p>

                    <div className="flex gap-5">
                        {estab?.phoneNumber && estab?.isPhoneNumberWhatsapp ? (
                            <a
                                href={`https://wa.me/${estab?.phoneNumber.replace(/\D/g, '')}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button
                                    className="btn btn-xs flex items-center justify-center text-white bg-green-500 hover:bg-green-600"
                                >
                                    <i className="fa-brands fa-whatsapp"></i>
                                </button>
                            </a>
                        ) : (
                            <button
                                className="btn btn-xs flex items-center justify-center text-white bg-gray-400 cursor-not-allowed"
                                disabled
                            >
                                <i className="fa-brands fa-whatsapp"></i>
                            </button>
                        )}

                        {estab?.location ? (
                            <a
                                href={estab?.location}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button
                                    className="btn btn-xs flex items-center justify-center text-white"
                                >
                                    <i className="fa-solid fa-location-dot"></i>
                                </button>
                            </a>
                        ) : (
                            <button
                                className="btn btn-xs flex items-center justify-center text-white bg-gray-400 cursor-not-allowed"
                                disabled
                            >
                                <i className="fa-solid fa-location-dot"></i>
                            </button>
                        )}

                        {estabsUser.some((e) => e._id === estab._id) && (
                            <ToggleAddEstabUserBtn estabId={estab._id} small />
                        )}
                    </div>
                    {estabsUser.some((e) => e._id === estab._id && e.type === 'client') ? (
                        <button className="btn btn-primary">
                            AGENDAR UM HORÁRIO AGORA!
                        </button>
                    ) : (
                        <ToggleAddEstabUserBtn estabId={estab._id} />
                    )}
                </div>
            </div>

            <div className="divider">Serviços</div>

            {estab?.servicos?.map((s: any, i: number) => (
                <div key={i} className="border-b border-gray-200 p-2">
                    <div className="flex justify-between">
                        <div>
                            <h3 className="font-semibold">{s.name}</h3>
                            <p className="text-xs text-gray-500">{s.description}</p>
                            <span className="text-xs font-medium">R$ {formatToReal(s.price)}</span>
                        </div>

                        {estabsUser.some((e) => e._id === estabId) && (
                            <button className="btn btn-primary btn-sm">Agendar</button>
                        )}
                    </div>

                    {s?.imagesIds?.length > 0 && (
                        <div className="flex overflow-x-auto space-x-2">
                            {s.imagesIds.map((fileId: string) => (
                                <FilePreviewImage key={fileId} fileId={fileId} />
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </Modal>
    );
};
