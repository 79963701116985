import { useFormHorarioClienteContext } from './Context';
import moment from 'moment';
import { convertMinutesToHHMM } from '../../../../components/utils';
import { Loading } from '../../../../components/Loading';

export const FormAgendamentoConfirmacao = () => {
    const { estab, servico, atendente, data, horario, submitForm, openConfirm, setOpenConfirm, loading } =
        useFormHorarioClienteContext();

    return (
        <div>
            {!!estab?._id && !!servico?._id && !!atendente?.uid && !!horario && (
                <div className={`flex justify-center mt-7 pb-10 animate__animated animate__faster animate__bounceIn`}>
                    <button
                        disabled={loading}
                        className="btn btn-primary btn-lg"
                        onClick={() => setOpenConfirm(true)}
                    >
                        {loading ? <Loading /> : 'Confirmar Agendamento'}
                    </button>
                </div>
            )}

            {openConfirm && (
                <div
                    className="card glass inset-0 bg-black bg-opacity-70 z-50 animate__animated animate__faster animate__fadeInLeft"
                    style={{ position: 'absolute' }}
                >
                    <div className="card-body">
                        <h2 className="card-title">Confirmação de Agendamento</h2>
                        <div className="divider"></div>
                        <div className="flex flex-col gap-2">
                            <div>
                                <strong>Estabelecimento:</strong> {estab?.name}
                            </div>
                            <div>
                                <strong>Serviço:</strong> {servico?.name}
                            </div>
                            <div>
                                <strong>Atendente:</strong> {atendente?.displayName}
                            </div>
                            <div>
                                <strong>Horário:</strong> {moment(data).format('dddd, DD [de] MMMM [de] YYYY')} às{' '}
                                {convertMinutesToHHMM(horario)}
                            </div>
                        </div>

                        <div className='divider'></div>

                        <div className="card-actions">
                            <button disabled={loading} className="btn btn-ghost" onClick={() => setOpenConfirm(false)}>
                                Voltar
                            </button>
                            <button disabled={loading} className="btn btn-primary" onClick={submitForm}>
                                {loading ? <Loading /> : 'Agendar!'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
