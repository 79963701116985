import { useEstabFormContext } from './Context';
import PhoneNumber from '../../../../components/inputs/PhoneNumber';

export function ContatoEstabelecimento() {
    const {
        phoneNumber,
        setPhoneNumber,
        isPhoneNumberWhatsapp,
        setIsPhoneNumberWhatsapp,
        instagram,
        setInstagram,
        facebook,
        setFacebook,
        twitter,
        setTwitter,
    } = useEstabFormContext();

    return (
        <>
            <PhoneNumber value={phoneNumber} setValue={setPhoneNumber} label="Número de telefone"/>

            <div className="form-control mb-4">
                <label className="label" htmlFor="instagram">
                    <span className="label-text">Whatsapp?</span>
                </label>
                <input
                    type="checkbox"
                    className="toggle toggle-sm w-full max-w-96"
                    checked={isPhoneNumberWhatsapp}
                    onChange={() => setIsPhoneNumberWhatsapp(!isPhoneNumberWhatsapp)}
                />
            </div>

            <div className="form-control mb-4">
                <label className="label" htmlFor="instagram">
                    <span className="label-text">Instagram:</span>
                </label>
                <input
                    type="url"
                    id="instagram"
                    value={instagram}
                    onChange={(e) => setInstagram(e.target.value)}
                    className="input input-bordered w-full max-w-96"
                />
            </div>

            <div className="form-control mb-4">
                <label className="label" htmlFor="facebook">
                    <span className="label-text">Facebook:</span>
                </label>
                <input
                    type="url"
                    id="facebook"
                    value={facebook}
                    onChange={(e) => setFacebook(e.target.value)}
                    className="input input-bordered w-full max-w-96"
                />
            </div>
            <div className="form-control mb-4">
                <label className="label" htmlFor="twitter">
                    <span className="label-text">X (Twitter)</span>
                </label>
                <input
                    type="url"
                    id="twitter"
                    value={twitter}
                    onChange={(e) => setTwitter(e.target.value)}
                    className="input input-bordered w-full max-w-96"
                />
            </div>
        </>
    );
}
