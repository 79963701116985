import React from 'react';
import { useParamsContext } from '../../../../contexts/ParamsContext';
import { CardAgendamento } from './Card';
import { useAgendaClienteContext } from '../Context';

export const AgendaList: React.FC = () => {
    const { setParams } = useParamsContext();
    const { loading, agendamentos, setOnlyActive, onlyActive } = useAgendaClienteContext();

    if (loading) {
        return <div className="text-center">Carregando agendamentos...</div>;
    }

    return (
        <div>
            <div className="flex justify-end">
                <label className="cursor-pointer label">
                    <span className="label-text">Apenas Agendados</span>
                    <input
                        type="checkbox"
                        onChange={() => setOnlyActive(!onlyActive)}
                        className="checkbox checkbox-primary ml-2"
                        checked={onlyActive}
                    />
                </label>
            </div>

            <div className="divider" style={{ padding: 0, margin: 0 }}></div>
            {agendamentos.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 justify-items-center">
                    {agendamentos.map((agendamento) => (
                        <CardAgendamento key={agendamento._id} agendamento={agendamento} />
                    ))}
                </div>
            ) : (
                <div>
                    <div className="text-center text-4xl text-gray-700 mb-6">Nenhum agendamento</div>
                    <div className="flex flex-col items-center">
                        <button
                            onClick={() => setParams({ action: 'add' })}
                            className="btn btn-primary btn-lg btn-circle bottom-6 shadow-2xl hover:bg-blue-600 transform hover:scale-105 transition-all duration-300"
                        >
                            <i className="fa-solid fa-plus"></i>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AgendaList;
