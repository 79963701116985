import { api } from '../../AxiosConfig';

class HorariosAtendenteService {
    cancel(horarioId: string) {
        return api.post(`/horarios/atendente/cancel`, { horarioId });
    }

    approve(horarioId: string) {
        return api.post(`/horarios/atendente/approve`, { horarioId });
    }

    conclude(horarioId: string) {
        return api.post(`/horarios/atendente/conclude`, { horarioId });
    }

    getHorariosCards(date: Date, attendantId: string) {
        const dateString = date.toISOString();
        return api.get(`/horarios/atendente/cards`, {
            params: { date: dateString, attendantId },
        });
    }

    createAgendamento(agendamentoData: {
        startAt: Date;
        servicoId: string;
        attendantId: string;
        startMinutes: number;
        clientId: string;
    }) {
        return api.post(`/horarios/atendente/agendar`, agendamentoData);
    }
}

export { HorariosAtendenteService };
