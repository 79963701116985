import { FormAgendamentoConfirmacao } from './Confirmacao';
import { AgendaFormHeader } from './Header';
import { SelectHorario } from './SelectHorario';
import { SelectServico } from './SelectServico';
import { FormHorarioClienteProvider } from './Context';

export const AgendaForm = () => {
    return (
        <FormHorarioClienteProvider>
            <div className="w-full relative">
                <AgendaFormHeader />
                <SelectServico />
                <SelectHorario />
                <FormAgendamentoConfirmacao />
            </div>
        </FormHorarioClienteProvider>
    );
};
