import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLandingPageContext } from "../Context";
import { motion } from "framer-motion";

export const Hero = () => {
    const navigate = useNavigate();
    const { estab } = useLandingPageContext();
    const images = estab?.imagesHeader || [];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isLoaded, setIsLoaded] = useState<{ [key: number]: boolean }>({});

    // Preload images
    useEffect(() => {
        images.forEach((src: any, index: any) => {
            const img = new Image();
            img.src = src;
            img.onload = () => setIsLoaded((prev) => ({ ...prev, [index]: true }));
        });
    }, [images]);

    // Interval to change images when the next one is loaded
    useEffect(() => {
        const interval = setInterval(() => {
            const nextIndex = (currentIndex + 1) % images.length;
            if (isLoaded[nextIndex]) {
                setCurrentIndex(nextIndex);
            }
        }, 9000);

        return () => clearInterval(interval);
    }, [currentIndex, images, isLoaded]);

    return images.length ? (
        <div className="hero min-h-[70vh] shadow-2xl relative overflow-hidden">
            <div className="absolute w-full h-full overflow-hidden">
                {images.map((src: any, index: any) => (
                    <motion.img
                        key={src}
                        src={src}
                        alt="header"
                        className="absolute w-full h-full object-cover"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: index === currentIndex ? 1 : 0 }}
                        transition={{ duration: 1, ease: "easeInOut" }} // Efeito de transição mais suave
                        style={{
                            visibility: isLoaded[index] ? "visible" : "hidden",
                        }}
                    />
                ))}
            </div>

            {/* Overlay content */}
            <motion.div
                className="absolute inset-0 bg-black/30 backdrop-blur-md p-8 grid place-content-center text-center space-y-6 z-40 h-min my-auto"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.5 }}
                style={{
                    boxShadow: "#000000b5 0 15px 40px",
                }}
            >
                <h2 className="text-5xl font-bold text-white text-shadow">{estab?.title}</h2>
                <p className="text-gray-300 text-shadow">{estab?.description}</p>
                <button
                    onClick={() => navigate("/login", { state: { estabId: estab?._id } })}
                    className="btn bg-black flex items-center gap-2 shadow-2xl shadow-black"
                >
                    Agende seu Horário
                </button>
            </motion.div>
        </div>
    ) : (
        <div className="text-center text-white py-5 pt-24">
            <h1 className="mb-5 text-5xl font-bold text-white text-shadow">{estab?.title}</h1>
            {estab?.description && <p className="mb-5 text-gray-300 text-shadow">{estab?.description}</p>}
            <button
                onClick={() => navigate("/login")}
                className="btn btn-primary flex items-center gap-2 shadow-2xl shadow-black"
            >
                Agende seu Horário
            </button>
        </div>
    );
};
