interface DateItemProps {
    setOpenConfig: any;
}

export const DateItemActions = ({ setOpenConfig }: DateItemProps) => {
    return (
        <div className="flex items-center ml-auto">
            <button
                className="btn btn-xs md:btn-sm animate-fade-in"
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setOpenConfig(true);
                }}
            >
                <i className="fa-solid fa-gear"></i>
            </button>
        </div>
    );
};
