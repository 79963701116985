import { useEffect, useState } from 'react';
import MultiSelect from './MultiSelect';
import { AtendentesService } from '../../modules/atendentes/Service';

const service = new AtendentesService();

export default function SelectAtendentes({
                                             name,
                                             value,
                                             setValue,
                                             multiple = true,
                                             label,
                                             placeholder,
                                         }: {
    name: string;
    value: string[] | string;
    setValue: (value: any) => void;
    multiple?: boolean;
    label?: string;
    placeholder?: string;
}) {
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState<{ value: string; label: string }[]>([]);

    useEffect(() => {
        service.getAll().then((response) => {
            const atendentes: any = response?.data?.data || [];
            setOptions(atendentes.map((e: any) => ({ value: e.uid, label: e.displayName })));
            setLoading(false);
        });
    }, []);

    return (
        <MultiSelect
            options={options}
            loading={loading}
            value={value}
            onChange={setValue}
            placeholder={placeholder || 'Selecione os atendentes'}
            label={label || 'Atendentes'}
            name={name}
            multiple={multiple}
        />
    );
}
