import { AgendaSettingsProvider, useAgendaSettingsContext } from './Context';
import AgendaSettingsGeral from './Geral';
import AgendaSettingsHorarios from './Horarios';
import { PermissionsSchedule } from './Permissions';
import { useAuthContext } from '../../../../contexts/AuthContext';
import SelectAtendentes from '../../../../components/inputs/SelectAtendentes';
import { AccordionItem } from '../../../../components/AccourdionItem';

function AgendaSettings() {
    const { user } = useAuthContext();
    const { atendenteId, setAtendenteId } = useAgendaSettingsContext();

    return (
        <div className="space-y-5">
            {
                (user?.root || user?.admin) && <div>
                    <SelectAtendentes label="Agenda do atendente:" value={atendenteId} setValue={setAtendenteId}
                                      name={'atendente'} placeholder={'Agenda Geral'} multiple={false} />
                </div>
            }

            <div className="join join-vertical w-full">
                <AccordionItem title="Geral" open={true}>
                    <AgendaSettingsGeral />
                </AccordionItem>

                <AccordionItem title="Horarios">
                    <AgendaSettingsHorarios />
                </AccordionItem>
            </div>

            {
                (user?.root || user?.admin) && <AccordionItem title="Administrativo">
                    <PermissionsSchedule />
                </AccordionItem>
            }
        </div>
    );
}

export default function _() {
    return (
        <AgendaSettingsProvider>
            <AgendaSettings />
        </AgendaSettingsProvider>
    );
}
