import ReactApexChart from 'react-apexcharts';
import { useEffect, useState } from 'react';
import { StatusHorario } from '../agenda/Enum';
import { ApexOptions } from 'apexcharts';
import { DashboardService } from './service';
import { useDashboardContext } from './Context';

const service = new DashboardService();

export const ChatsStatus: React.FC = () => {
    const { atendenteId } = useDashboardContext();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>([]);
    const [state, setState] = useState<any>({
        series: [],
        options: {
            chart: {
                type: 'bar' as 'bar',
                height: 350,
                stacked: true,
            },
            title: {
                text: 'Status atuais',
                align: 'left',
                margin: 0,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '20px',
                },
            },
            subtitle: {
                text: 'Status atuais por atendentes',
                align: 'left',
                margin: 0,
                offsetX: 0,
                offsetY: 25,
                floating: false,
                style: {
                    fontSize: '13px',
                },
            },
            stroke: {
                width: 1,
                colors: ['#fff'],
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        total: {
                            enabled: true,
                            offsetX: 0,
                            style: {
                                fontSize: '13px',
                                fontWeight: 900,
                            },
                        },
                    },
                },
            },
            xaxis: {
                categories: [
                    StatusHorario.PENDENTE,
                    StatusHorario.AGENDADO,
                    StatusHorario.CANCELADO,
                    StatusHorario.CONCLUIDO,
                ],
            },
            legend: {
                position: 'top' as 'top',
                horizontalAlign: 'left',
                offsetX: 40,
                formatter: (seriesName: string, opts: any) => {
                    return seriesName;
                },
            },
            fill: {
                opacity: 1,
            },
        } as ApexOptions,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                const response = await service.getStatusAtual(atendenteId);
                setData(response.data)
                const formattedData = response.data.map((item: any) => {
                    return {
                        name: item.attendant.name,
                        photoURL: item.attendant.photoURL,
                        data: [
                            item.statusCounts[StatusHorario.PENDENTE] || 0,
                            item.statusCounts[StatusHorario.AGENDADO] || 0,
                            item.statusCounts[StatusHorario.CANCELADO] || 0,
                            item.statusCounts[StatusHorario.CONCLUIDO] || 0,
                        ],
                    };
                });

                setState((prevState: any) => {
                    prevState.options.tooltip = {
                        x: {
                            show: false,
                        },
                        y: {
                            title: {
                                formatter: function (val: any, opts: any) {
                                    const user = response.data.find((e: any) => e.attendant.name === val)?.attendant;

                                    const style = 'width: 30px; height: 30px; border-radius: 50%; margin-right: 10px;';

                                    return `
                                        <div style="display: flex; align-items: center;">
                                            ${user?.photoURL ? `<img src="${user.photoURL}" alt="${user.name}" style="${style}" />` : ''}
                                            <strong>${user?.name || 'Desconhecido'}</strong><br>
                                        </div>
                                        ${opts.w.globals.labels[opts.dataPointIndex]}
                                    `;
                                },
                            },
                        },
                    };

                    return {
                        ...prevState,
                        series: formattedData,
                    };
                });
            } catch (e) {
                console.error('Erro ao buscar dados:', e);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [atendenteId]);

    return (
        <div className={`${loading && 'opacity-40 cursor-not-allowed'}`}>
            <ReactApexChart key={data?.length} options={state.options} series={state.series} type="bar" height={350} />
        </div>
    );
};
