import { useState } from 'react';
import { Loading } from '../../../../components/Loading';

export const CancelModal = ({ onConfirm, onCancel }: { onConfirm: () => void; onCancel: () => void }) => {
    const [loading, setLoading] = useState(false);

    const onClickConfirm = async () => {
        try {
            setLoading(true);
            await onConfirm();
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex justify-between">
            <button className="btn btn-ghost" onClick={onCancel} disabled={loading}>
                Cancelar
            </button>
            <button className="btn btn-primary" onClick={onClickConfirm} disabled={loading}>
                {loading ? <Loading /> : 'Confirmar'}
            </button>
        </div>
    );
};
