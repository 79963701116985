import { useDashboardContext } from './Context';
import { useEffect, useRef, useState } from 'react';
import { DashboardService } from './service';

const service = new DashboardService();

interface DashboardData {
    totalSales: number;
    completedAppointments: number;
    returnRate: number;
    totalSalesLastMonth: number;
    completedAppointmentsLastMonth: number;
}

export const DashboardHeader = () => {
    const [loading, setLoading] = useState(false);
    const { competence, atendenteId } = useDashboardContext();
    const previousCompetenceRef = useRef(competence);

    const [data, setData] = useState<DashboardData>({
        totalSales: 0,
        completedAppointments: 0,
        returnRate: 0,
        totalSalesLastMonth: 0,
        completedAppointmentsLastMonth: 0,
    });

    useEffect(() => {
        const fetchData = async (competence: string) => {
            if (!loading) {
                try {
                    setLoading(true);
                    const response = await service.getInfoHeader(competence, atendenteId);
                    setData(response.data);
                } catch (e) {
                    console.error('Erro ao carregar os dados do dashboard:', e);
                } finally {
                    setTimeout(function () {
                        setLoading(false);

                        if (previousCompetenceRef.current !== competence) {
                            fetchData(previousCompetenceRef.current);
                        }
                    }, 300);
                }
            }
        };

        previousCompetenceRef.current = competence;
        fetchData(competence);
    }, [competence, atendenteId]);

    return (
        <div className={`mb-4 ${loading && 'opacity-30 cursor-not-allowed'} space-y-4 sm:space-y-0 sm:stats`}>
            <div className="stat bg-cyan-50">
                <div className="stat-figure text-cyan-500">
                    <i className="fa-solid fa-rotate-left text-2xl"></i>
                </div>
                <div className="text-gray-500 text-xs sm:text-md">Taxa de Retorno</div>
                <div className="text-cyan-600 font-medium text-xl sm:text-3xl">{data.returnRate}%</div>
                <div className="stat-desc text-xs text-gray-400">Mês anterior</div>
            </div>

            <div className="stat bg-green-50">
                <div className="stat-figure text-green-500">
                    <i className="fa-regular fa-circle-check text-2xl"></i>
                </div>
                <div className="text-xs sm:text-md text-gray-500">Concluídos</div>
                <div className="text-green-500 font-medium text-xl sm:text-3xl">{data.completedAppointments}</div>
                {!!data.completedAppointmentsLastMonth && (
                    <div className="stat-desc text-xs text-gray-400">
                        {data.completedAppointmentsLastMonth} (Mês anterior)
                    </div>
                )}
            </div>

            <div className="stat bg-yellow-50">
                <div className="stat-figure text-yellow-600">
                    <i className="fa-solid fa-dollar-sign text-2xl"></i>
                </div>
                <div className="text-xs sm:text-md text-gray-500">Faturamento</div>
                <div className="text-yellow-600 font-medium text-xl sm:text-3xl">
                    R${' '}
                    {data.totalSales.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                    })}
                </div>
                {!!data.totalSalesLastMonth && (
                    <div className="stat-desc text-xs text-gray-400">
                        R${' '}
                        {data.totalSalesLastMonth.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                        })}{' '}
                        (Mês anterior)
                    </div>
                )}
            </div>
        </div>
    );
};
