import { useNotificationsContext } from './NotificationsContext';
import NotificationItem from './Item';
import { useNavigate } from 'react-router-dom';
import Dropdown from '../modules/agenda/estabelecimento/components/Dropdown';
import { NotificationsService } from './Service';
import { useState, useEffect, useRef } from 'react';

const service = new NotificationsService();

export default function Notifications() {
    const [loading, setLoading] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const {
        notifications,
        countNotVisualized,
        dropdownOpen,
        setDropdownOpen,
        onlyNotVisualized,
        setOnlyNotVisualized,
        loadNotifications,
    } = useNotificationsContext();

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setDropdownOpen(false);
            }
        };
        if (dropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownOpen]);

    return (
        <div className="mt-1" ref={dropdownRef}>
            <div className="indicator">
                {countNotVisualized > 0 && (
                    <span className="indicator-item">
                        <span className="relative flex items-center">
                            <span
                                className="absolute inline-flex h-full w-full animate-ping rounded-full bg-primary opacity-80"></span>
                            <span className="relative inline-flex h-2 w-2 rounded-full bg-primary"></span>
                        </span>
                    </span>
                )}
                <button
                    className="btn btn-circle btn-xs"
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                    <i className="fa-regular fa-bell"></i>
                </button>
            </div>
            {dropdownOpen && (
                <div
                    className="absolute right-0 mt-6 w-full sm:w-96 bg-base-100 text-base-content shadow-2xl rounded-xl z-50 max-h-[70vh] overflow-y-auto"
                >
                    <div className="p-4">
                        <div className="flex justify-between items-center mb-2">
                            <h3 className="text-lg font-semibold">Notificações</h3>
                            <Dropdown
                                options={[
                                    {
                                        label: 'Ler todas',
                                        onClick: async () => {
                                            setLoading(true);
                                            try {
                                                await service.viewAll();
                                                loadNotifications();
                                            } catch (e) {
                                            } finally {
                                                setLoading(false);
                                            }
                                        },
                                    },
                                    {
                                        label: 'Remover todas',
                                        onClick: async () => {
                                            setLoading(true);
                                            try {
                                                await service.removeAll();
                                                loadNotifications();
                                            } catch (e) {
                                            } finally {
                                                setLoading(false);
                                            }
                                        },
                                    },
                                ]}
                            >
                                <button disabled={loading} className="btn btn-sm opacity-80">
                                    <i className="fa-solid fa-ellipsis-vertical"></i>
                                </button>
                            </Dropdown>
                        </div>

                        <div className="form-control mb-2">
                            <label className="label cursor-pointer">
                                <span className="label-text">Apenas não lidas</span>
                                <input
                                    type="checkbox"
                                    className="toggle toggle-sm"
                                    checked={onlyNotVisualized}
                                    onChange={() => setOnlyNotVisualized(!onlyNotVisualized)}
                                />
                            </label>
                        </div>

                        <div className="divider my-2"></div>

                        {notifications.length === 0 ? (
                            <p className="text-sm text-gray-500 text-center">Nenhuma notificação</p>
                        ) : (
                            <ul className="space-y-2">
                                {notifications.map((notification, index) => (
                                    <NotificationItem key={index} notification={notification} />
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
