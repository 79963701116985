import moment from 'moment';
import { useParamsContext } from '../../../contexts/ParamsContext';
import ScheduleStatus from './components/ScheduleStatus';
import { Agendamento } from '../Enum';

moment.locale('pt-br');

interface ScheduleItemProps {
    schedule: Agendamento;
}

export default function ScheduleItem({ schedule }: ScheduleItemProps) {
    const { setParams } = useParamsContext();

    const now = moment();
    const start = moment(schedule.startAt);
    const end = moment(schedule.endAt);

    const handleScheduleClick = () => {
        setParams({ action: 'view', id: schedule._id });
    };

    return (
        <div className="cursor-pointer p-1 h-full" onClick={handleScheduleClick}>
            <div className="flex justify-between">
                <div className="w-full">
                    <div className="text-xs font-bold flex gap-1">
                        {now.isBetween(start, end, null, '[]') && (
                            <span className="relative flex items-center" style={{ zIndex: -1 }}>
                                <span
                                    className="absolute inline-flex h-full w-full animate-ping rounded-full bg-sky-400 opacity-75"></span>
                                <span className="relative inline-flex h-3 w-3 rounded-full bg-sky-500"></span>
                            </span>
                        )}
                        <span>
                            {start.format('HH:mm')} - {end.format('HH:mm')} | {schedule.service.name}
                        </span>
                    </div>
                    <div className="text-xs font-light">
                        <p>Cliente: {schedule.client.name}</p>
                        <p>Atendente: {schedule.attendant.name}</p>
                    </div>
                </div>
            </div>

            <div className="text-xs" onClick={handleScheduleClick}>
                {schedule?.confirmation?.date && (
                    <div className="badge badge-success badge-outline badge-sm">
                        <i className="fa-solid fa-calendar-check mr-1"></i>
                        Confirmado {moment(schedule.confirmation.date).fromNow()}
                    </div>
                )}

                {schedule?.cancellation?.date && (
                    <div className="badge badge-error badge-outline badge-sm">
                        <i className="fa-solid fa-calendar-xmark mr-1"></i>
                        Cancelado {moment(schedule.cancellation.date).fromNow()}
                    </div>
                )}
            </div>
        </div>
    );
}
