import { useParamsContext } from '../../contexts/ParamsContext';

interface Actions {
    type: string;
}

interface ContentActionProps {
    actions: Actions[];
}

export default function ContentActions(options: ContentActionProps) {
    const { setParams, action: actionParam } = useParamsContext();

    const parserAction = (action: any) => {
        switch (action.type) {
            case 'add':
                return (
                    <li
                        onClick={() => {
                            setParams({ action: 'add' });
                        }}
                    >
                        <i className="fa-solid fa-plus text-xl text-primary"></i>
                    </li>
                );
            case 'settings':
                return (
                    <li
                        onClick={() => {
                            setParams({ action: 'settings' });
                        }}
                    >
                        <i className="fa-solid fa-gear text-xl"></i>
                    </li>
                );
            default:
                return <></>;
        }
    };

    return (
        <ul className="menu menu-horizontal rounded-lg">
            {options.actions.map((action, i) => (
                <div key={i}>{parserAction(action)}</div>
            ))}
        </ul>
    );
}
