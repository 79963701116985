import { useEffect, useState } from 'react';
import CarouselImgs from '../../../../components/files/CarouselImg';
import { formatToReal } from '../../../../components/utils';
import { DurationService } from '../../../servicos/list/Duration';
import { Loading } from '../../../../components/Loading';
import { ServicosService } from '../../../servicos/Service';
import { useFormHorarioClienteContext } from './Context';
import { useEstabsContext } from '../../../../contexts/EstabsContext';

const service = new ServicosService();

export const SelectServico = () => {
    const { estabSession } = useEstabsContext();

    const [loading, setLoading] = useState(false);
    const [servicos, setServicos] = useState([] as any);
    const { servico, setServico } = useFormHorarioClienteContext();

    const loadServicos = async () => {
        try {
            setLoading(true);
            const { data } = await service.getByEstabId(estabSession._id);
            setServicos(data);
        } catch (err) {
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!!estabSession?._id) loadServicos();
    }, [estabSession]);

    if (loading)
        return (
            <div className="flex justify-center pt-10">
                <Loading />
            </div>
        );

    return (
        <div className="w-full flex justify-center px-4">
            <div className="w-full max-w-3xl flex flex-col gap-2">
                {!!estabSession?._id &&
                    servicos.map((e: any, i: any) => (
                        <div
                            key={i}
                            className={`flex items-center gap-4 p-3 bg-white hover:bg-gray-50 transition-all cursor-pointer rounded-xl shadow-sm hover:shadow-md ${
                                !!servico?._id ? 'hidden' : ''
                            }`}
                            onClick={() => setServico(e)}
                        >
                            {/* Miniatura da imagem */}
                            {!!e?.imagesIds?.length && (
                                <div className="w-16 h-16 rounded-lg overflow-hidden flex-shrink-0">
                                    <CarouselImgs imagesIds={e.imagesIds} autoPlay />
                                </div>
                            )}

                            {/* Informações do serviço */}
                            <div className="flex-1">
                                <h2 className="text-base font-semibold text-gray-800">{e?.name}</h2>
                                {e?.description && (
                                    <p className="text-sm text-gray-500 mt-0.5">{e.description}</p>
                                )}
                                <div className="flex gap-6 mt-2 text-sm text-gray-600">
                                    <span className="flex items-center gap-1">
                                        ⏱ <DurationService duration={e.duration} />
                                    </span>
                                    <span className="flex items-center gap-1">
                                        💰 <span className="font-semibold text-blue-500">R$ {formatToReal(e.price)}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};
