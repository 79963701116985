import moment from 'moment';
import ScheduleStatus from '../components/ScheduleStatus';
import { getInitialName } from '../../../../components/utils';
import { Agendamento } from '../../Enum';
import { baseURL } from '../../../../AxiosConfig';

export interface ScheduleViewHeaderProps {
    schedule: Agendamento;
}

export default function ScheduleViewHeader({ schedule }: ScheduleViewHeaderProps) {
    const start = moment(schedule.startAt);
    const end = moment(schedule.endAt);

    return (
        <div className="w-full sm:stats">
            <div className="sm:stat grid justify-items-center">
                <div className="flex gap-2">
                    <div className="avatar h-14 w-14">
                        <div
                            className="mask mask-squircle bg-neutral text-neutral-content flex items-center justify-center h-full w-full overflow-hidden">
                            <img
                                src={`${baseURL}/users/profileImage/${schedule.client.uid}`}
                                alt="Foto do Cliente"
                                className="h-full w-full object-cover"
                            />
                        </div>
                    </div>

                    <div>
                        <div>
                            <div className="text-lg">{schedule.client.name}</div>
                        </div>

                        {schedule.client.phoneNumber ? (
                            <a
                                href={`https://wa.me/${schedule.client.phoneNumber.replace(/\D/g, '')}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button
                                    className="btn btn-circle btn-xs flex items-center justify-center text-white bg-green-500 hover:bg-green-600"
                                >
                                    <i className="fa-brands fa-whatsapp"></i>
                                </button>
                            </a>
                        ) : (
                            <button
                                className="btn btn-circle btn-xs flex items-center justify-center text-white bg-gray-400 cursor-not-allowed"
                                disabled
                            >
                                <i className="fa-brands fa-whatsapp"></i>
                            </button>
                        )}
                    </div>
                </div>

                <div>
                    <div className="text-xs my-2">
                        {schedule.client.email}
                        {!!schedule.client.phoneNumber && ` | ${schedule.client.phoneNumber}`}
                    </div>
                </div>
                <div className="stat-desc text-primary">
                    {(Number(schedule.client.amountSchedules) || 0) > 1
                        ? `${schedule.client.amountSchedules} agendamentos realizados`
                        : Number(schedule.client.amountSchedules) === 1
                            ? 'Primeiro agendamento realizado'
                            : 'Nenhum agendamento realizado'}
                </div>
            </div>

            <div className="sm:stat grid justify-items-center pb-5">
                <div className="text-xs opacity-70 font-bold">{start.format('dddd, D [de] MMMM [de] YYYY')}</div>
                <div className="stat-value text-primary">
                    {start.format('HH:mm')} - {end.format('HH:mm')}
                </div>

                <ScheduleStatus
                    clientId={schedule.client.uid}
                    key={schedule._id}
                    status={schedule.status}
                    id={schedule._id}
                />
            </div>
        </div>
    );
}
