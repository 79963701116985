import { useClientsContext } from './Context';
import { Modal } from '../../components/Modal';
import { Loading } from '../../components/Loading';
import React, { useState } from 'react';
import { UserService } from '../configuracoes/usuario/Service';
import { useAppContext } from '../../contexts/AppContext';
import { ShareAgenda } from './Share';

const service = new UserService();

export const ActionsClients = () => {
    const { successSnackbar, errorSnackbar } = useAppContext();

    const { loadClients } = useClientsContext();
    const [openAdd, setOpenAdd] = useState(false);
    const [openShare, setOpenShare] = useState(false);

    const [loading, setLoading] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const addClient = async (e: React.FormEvent) => {
        e.preventDefault();

        setLoading(true);

        try {
            await service.create({
                name,
                email,
            });
            successSnackbar('Cliente adicionado com sucesso!');

            loadClients();
            setOpenAdd(false);
            setName('');
            setEmail('');
        } catch (error) {
            errorSnackbar('Ocorreu um erro ao adicionar o cliente');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {openAdd && (
                <Modal onClose={() => setOpenAdd(false)} title="Adicionar Cliente">
                    <form onSubmit={addClient}>
                        <label className="input input-bordered flex items-center gap-2 mt-2">
                            <i className="fa-solid fa-user"></i>
                            <input
                                type="text"
                                className="grow"
                                placeholder="Nome"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </label>

                        <label className="input input-bordered flex items-center gap-2 mt-4">
                            <i className="fa-solid fa-envelope"></i>
                            <input
                                type="email"
                                className="grow"
                                placeholder="E-mail"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </label>

                        <div className="divider"></div>

                        <div className="flex justify-between mt-4">
                            <button
                                type="button"
                                className="btn btn-ghost"
                                onClick={() => setOpenAdd(false)}
                                disabled={loading}
                            >
                                Cancelar
                            </button>
                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                {loading ? <Loading /> : 'Adicionar'}
                            </button>
                        </div>
                    </form>
                </Modal>
            )}

            {openShare && (
                <Modal onClose={() => setOpenShare(false)} title="Compartilhar"
                       subTitle="Compartilhe sua agenda com seus clientes!">
                    <ShareAgenda />
                </Modal>
            )}

            <ul className="menu menu-horizontal rounded-lg">
                <li
                    onClick={() => {
                        setOpenAdd(true);
                    }}
                >
                    <i className="fa-solid fa-plus text-xl text-primary"></i>
                </li>
                <li
                    onClick={() => {
                        setOpenShare(true);
                    }}
                >
                    <i className="fa-solid fa-share-nodes text-xl"></i>
                </li>
            </ul>
        </>
    );
};
