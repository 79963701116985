import { useEffect, useState } from 'react';
import { FileUpload } from '../../../../components/files/FilesUpload';

interface ImageEstabProps {
    imageId: string;
    setImageId: (imageId: string) => void;
}

export const ImageEstab = ({ imageId, setImageId }: ImageEstabProps) => {
    const [value, setValue] = useState<string[]>([imageId]);

    useEffect(() => {
        setImageId(value?.[0] || '');
    }, [value, setImageId]);

    return (
        <FileUpload value={value} setValue={setValue} multiple={false}>
            {({ fileData, handleChange, inputRef }) => {
                return (
                    <div className="grid">
                        <label>Logo:</label>
                        <div className="my-5">
                            {fileData?.[0]?.uploading ? (
                                <div className="flex items-center justify-center">
                                    <img
                                        src={fileData?.[0]?.fileContent}
                                        alt="file preview"
                                        style={{ width: '120px', height: '30px' }}
                                    />
                                    <div className="absolute inset-0 bg-black opacity-30"></div>
                                    {' '}
                                    <span
                                        className="loading loading-spinner loading-sm absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                                        style={{ color: '#ffffff' }}
                                    ></span>
                                </div>
                            ) : fileData?.[0]?.url ? (
                                <img
                                    src={fileData?.[0]?.url}
                                    alt="file preview"
                                    style={{ width: '120px', height: '30px' }}
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="mx-auto">
                            <input
                                type="file"
                                ref={inputRef}
                                accept="image/*"
                                onChange={(e: any) => {
                                    const selectedFiles: any = Array.from(e.target.files || []);
                                    handleChange(selectedFiles);
                                }}
                                className="file-input file-input-bordered file-input-xs w-36 md:w-44 md:file-input-sm"
                            />
                        </div>
                    </div>
                );
            }}
        </FileUpload>
    );
};
