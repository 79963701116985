import CustomDataTable from '../../components/table/Table';
import { AtendentesService } from './Service';
import AvatarUser from '../../components/AvatarUser';
import { useAtendentesContext } from './Context';
import Dropdown from '../../components/Dropdown';
import { useState } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import TableActions from '../../components/table/Actions';
import { useAuthContext } from '../../contexts/AuthContext';

const service = new AtendentesService();

interface DataRow {
    uid: string;
    displayName: string;
    email: string;
    photoURL: string;
    disabled: boolean;
    type: string;
    emailVerified: boolean;
}

export default function AtendentesList() {
    const { user } = useAuthContext();
    const { atendentes, loading } = useAtendentesContext();

    const columns = [
        {
            name: 'Nome',
            selector: (row: DataRow) => row.displayName,
            sortable: true,
            style: { whiteSpace: 'nowrap' },
            cell: (row: DataRow) => (
                <>
                    <AvatarUser photoURL={row.photoURL} name={row.displayName || row.email} />
                    <div className="ml-4" style={{ textAlign: 'right' }}>
                        {row.displayName}
                    </div>
                </>
            ),
        },
        {
            name: 'Email',
            selector: (row: DataRow) => row.email,
            sortable: true,
            style: { whiteSpace: 'nowrap' },
            cell: (row: DataRow) => (
                <div className="flex items-center">
                    {row.emailVerified ? (
                        <div className="tooltip fixed tooltip-bottom" data-tip={'Email verificado'}>
                            <i className="fas fa-check-circle mr-1 text-green-600" />
                        </div>
                    ) : (
                        <div className="tooltip fixed tooltip-bottom" data-tip={'Email não verificado'}>
                            <i className="fas fa-times-circle mr-1 text-red-600" />
                        </div>
                    )}

                    <div className="ml-4">{row.email}</div>
                </div>
            ),
        },
        {
            name: 'Admin.',style: { whiteSpace: 'nowrap' },
            right: true,
            cell: (row: DataRow) => {
                return <ChangeAdm row={row} />;
            },
        },
        {
            name: 'Ações',
            right: true,
            style: { whiteSpace: 'nowrap' },
            sortable: false,
            cell: (row: DataRow) => (
                <>
                    <TableActions
                        disabled={!(user?.root || user?.admin)}
                        row={row}
                        options={[
                            {
                                type: 'delete',
                            },
                        ]}
                    />
                </>
            ),
        },
    ];

    return (
        <CustomDataTable columns={columns} data={atendentes} loading={loading} pagination highlightOnHover striped />
    );
}

const ChangeAdm = ({ row }: { row: DataRow }) => {
    const [loading, setLoading] = useState(false);
    const { fetchAtendentes } = useAtendentesContext();

    return (
        <select
            onChange={async (e) => {
                try {
                    setLoading(true);

                    await service.update(row.uid, {
                        admin: e.target.value,
                    });

                    fetchAtendentes();
                } catch (e) {
                } finally {
                    setLoading(false);
                }
            }}
            disabled={loading}
            className="select select-sm"
            defaultValue={row.type === 'admin' ? 'sim' : 'nao'}
        >
            <option value="sim">Sim</option>
            <option value="nao">Não</option>
        </select>
    );
};
