import React, { createContext, useContext, useState, useEffect } from 'react';
import { NotificationsService } from './Service';
import { useAuthContext } from '../contexts/AuthContext';
import { PushNotification } from '../PushNotification';
import { WebSocketManager } from '../WebSocketConfig';

const service = new NotificationsService();

interface Notification {
    _id: string;
    title: string;
    message: string;
    path?: string;
    createdAt: Date;
    type?: string;
    visualizedAt?: Date;
}

interface NotificationsContextProps {
    notifications: Notification[];
    dropdownOpen: boolean;
    setDropdownOpen: (val: boolean) => void;
    onlyNotVisualized: boolean;
    setOnlyNotVisualized: (val: boolean) => void;
    countNotVisualized: number;
    loadNotifications: () => void;
    showNotification: (notification: Notification) => void;
}

const NotificationsContext = createContext<NotificationsContextProps | undefined>(undefined);

export const useNotificationsContext = () => {
    const context = useContext(NotificationsContext);
    if (!context) {
        throw new Error('useNotificationsContext must be used within a NotificationsProvider');
    }
    return context;
};

export const NotificationsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { user } = useAuthContext();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [onlyNotVisualized, setOnlyNotVisualized] = useState(localStorage.getItem('onlyNotVisualized') === 'true');
    const [data, setData] = useState<Notification[]>([]);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [countNotVisualized, setCountNotVisualized] = useState<number>(0);
    const [visibleNotifications, setVisibleNotifications] = useState<
        { notification: Notification; animatingOut: boolean }[]
    >([]);

    const loadNotifications = async () => {
        await service.getAll().then((response) => {
            setData(response.data);
        });
    };

    useEffect(() => {
        if (!user?.uid) return;

        loadNotifications();

        new PushNotification();
        const webSocketManager = new WebSocketManager();

        const handleNotification = (message: any) => {
            showNotification(message);
            loadNotifications();
        };

        setTimeout(() => {
            if (webSocketManager.socket) {
                webSocketManager.onChannel('newNotification', handleNotification);
            } else {
                console.error('Socket não inicializado corretamente');
            }
        }, 2000);

        return () => {
            webSocketManager.disconnect();
        };
    }, [user]);

    useEffect(() => {
        if (!!onlyNotVisualized) {
            setNotifications(data.filter((e) => !e.visualizedAt));
        } else {
            setNotifications(data);
        }

        const count = data?.filter((e) => !e.visualizedAt)?.length || 0;
        setCountNotVisualized(count);

        if (count > 0) {
            document.title = `(${count}) ${document.title.replace(/^\(\d+\)\s*/, '')}`;
        } else {
            document.title = document.title.replace(/^\(\d+\)\s*/, '');
        }
    }, [data, onlyNotVisualized]);

    const showNotification = ({ _id, title, message }: { _id: string; title: string; message: string }) => {
        const newNotification: Notification = {
            _id,
            title,
            message,
            createdAt: new Date(),
        };

        setVisibleNotifications((prev) => [...prev, { notification: newNotification, animatingOut: false }]);

        const notificationSound = new Audio('notification.mp3');
        notificationSound.play().catch((error) => console.error('Erro ao reproduzir som:', error));

        setTimeout(() => {
            removeNotification(newNotification._id);
        }, 5000);
    };

    const removeNotification = (id: string) => {
        setVisibleNotifications((prev) =>
            prev.map((n) => (n.notification._id === id ? { ...n, animatingOut: true } : n))
        );

        setTimeout(() => {
            setVisibleNotifications((prev) => prev.filter((n) => n.notification._id !== id));
        }, 1000);
    };

    return (
        <NotificationsContext.Provider
            value={{
                notifications,
                countNotVisualized,
                dropdownOpen,
                loadNotifications,
                setDropdownOpen,
                onlyNotVisualized,
                setOnlyNotVisualized: (value) => {
                    setOnlyNotVisualized(value);
                    localStorage.setItem('onlyNotVisualized', value.toString());
                },
                showNotification,
            }}
        >
            <div
                className="fixed"
                style={{
                    zIndex: 9999999,
                    width: '320px',
                    top: '20px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                }}
            >
                {visibleNotifications.map(({ notification, animatingOut }) => (
                    <div
                        key={notification._id}
                        role="alert"
                        style={{ width: '320px' }}
                        className={`alert shadow-xl animate__animated ${animatingOut ? 'animate__backOutUp' : 'animate__backInDown'} mb-3`}
                    >
                        <div className="flex justify-between"
                             style={{ width: '290px' }}
                        >
                            <div className="flex gap-2 items-center">
                                <i className="fa-solid fa-bell fa-fw text-primary"></i>

                                <div className="justify-items-start">
                                    <h3 className="font-bold">{notification.title}</h3>
                                    <div className="text-xs">{notification.message}</div>
                                </div>
                            </div>

                            <button className="btn btn-sm btn-circle"
                                    onClick={() => removeNotification(notification._id)}>
                                <i className="fa-solid fa-xmark"></i>
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            {children}
        </NotificationsContext.Provider>
    );
};
