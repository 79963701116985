import ConfiguracoesContaIdioma from './Idioma';
import ConfiguracoesContaNotifications from './Notifications';
import ConfiguracoesContaGeral from './conta/Geral';
import ConfiguracoesContaEmail from './conta/Email';
import ConfiguracoesContaPhoneNumber from './conta/PhoneNumber';
import ConfiguracoesContaPassword from './conta/Password';
import { useAuthContext } from '../../../contexts/AuthContext';
import AgendaSettingsGeral from '../../agenda/estabelecimento/settings/Geral';
import { AccordionItem } from '../../../components/AccourdionItem';

export default function ConfiguracoesConta() {
    const { user } = useAuthContext();

    return (
        <div className="join join-vertical w-full text-md md:text-xl">
            <AccordionItem title="Perfil" open={true}>
                <ConfiguracoesContaGeral />
            </AccordionItem>

            <AccordionItem title="Email">
                <ConfiguracoesContaEmail />
            </AccordionItem>

            <AccordionItem title={<>
                {!user?.phoneNumber && <i className="fa-solid fa-triangle-exclamation text-warning"></i>} Número Whatsapp
            </>}>
                <ConfiguracoesContaPhoneNumber />
            </AccordionItem>

            <AccordionItem title="Senha">
                <ConfiguracoesContaPassword />
            </AccordionItem>

            <AccordionItem title="Idioma">
                <ConfiguracoesContaIdioma />
            </AccordionItem>

            <AccordionItem title="Notificações">
                <ConfiguracoesContaNotifications />
            </AccordionItem>
        </div>
    );
}
