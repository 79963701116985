import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { EstabelecimentosService } from '../../../estabelecimentos/system-admin/Service';
import { useEstabsContext } from '../../../../contexts/EstabsContext';
import { useAppContext } from '../../../../contexts/AppContext';
import { Horarios, initialHorarios } from '../../Enum';
import { AtendentesService } from '../../../atendentes/Service';
import { useAuthContext } from '../../../../contexts/AuthContext';

const serviceEstab = new EstabelecimentosService();
const atendentesService = new AtendentesService();

interface AgendaSettingsContextProps {
    daysOpen: number;
    setDaysOpen: React.Dispatch<React.SetStateAction<number>>;
    horarios: Horarios;
    setHorarios: React.Dispatch<React.SetStateAction<Horarios>>;
    requireApproval: boolean;
    setRequireApproval: React.Dispatch<React.SetStateAction<boolean>>;
    requireConfirmation: boolean;
    setRequireConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
    hoursBeforeConfirmation: number;
    setHoursBeforeConfirmation: React.Dispatch<React.SetStateAction<number>>;
    sendWhatsapp: boolean;
    setSendWhatsapp: React.Dispatch<React.SetStateAction<boolean>>;
    attendantConfigure: boolean;
    setAttendantConfigure: React.Dispatch<React.SetStateAction<boolean>>;
    disabled: boolean;
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    scheduleGap: number;
    setScheduleGap: React.Dispatch<React.SetStateAction<number>>;
    minAdvanceTime: number;
    setMinAdvanceTime: React.Dispatch<React.SetStateAction<number>>;
    minutesBeforeCancellation: number;
    setMinutesBeforeCancellation: React.Dispatch<React.SetStateAction<number>>;
    allowCancellation: boolean;
    setAllowCancellation: React.Dispatch<React.SetStateAction<boolean>>;
    atendenteId: string;
    setAtendenteId: React.Dispatch<React.SetStateAction<string>>;
    submitGeral: () => void;
    submitHorarios: () => void;
    submitAdm: () => void;
    loading: boolean;
}

const validateHorarios = (horarios: Horarios): Horarios => {
    const validatedHorarios: Horarios = {};
    for (const [day, slots] of Object.entries(horarios)) {
        validatedHorarios[day] = slots.filter((slot) => slot.start > 0 && slot.end > 0 && slot.start < slot.end);
    }
    return validatedHorarios;
};

const AgendaSettingsContext = createContext<AgendaSettingsContextProps | undefined>(undefined);

const AgendaSettingsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { user } = useAuthContext();

    const { errorSnackbar, successSnackbar } = useAppContext();
    const { estabSession, reloadEstab } = useEstabsContext();
    const [atendenteId, setAtendenteId] = useState<string>(user?.worker ? user?.uid : '');

    const [allowCancellation, setAllowCancellation] = useState(false);
    const [minutesBeforeCancellation, setMinutesBeforeCancellation] = useState(60);
    const [loading, setLoading] = useState(false);
    const [daysOpen, setDaysOpen] = useState(10);
    const [horarios, setHorarios] = useState<Horarios>(initialHorarios);
    const [requireApproval, setRequireApproval] = useState(false);
    const [attendantConfigure, setAttendantConfigure] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [requireConfirmation, setRequireConfirmation] = useState(false);
    const [hoursBeforeConfirmation, setHoursBeforeConfirmation] = useState(24);
    const [sendWhatsapp, setSendWhatsapp] = useState(false);
    const [scheduleGap, setScheduleGap] = useState(10);
    const [minAdvanceTime, setMinAdvanceTime] = useState(30);

    const submitGeral = async () => {
        try {
            setLoading(true);

            if (!!atendenteId) {
                await atendentesService.updateAgenda(atendenteId, {
                    'agenda.daysOpen': daysOpen,
                    'agenda.scheduleGap': Math.max(10, scheduleGap),
                    'agenda.minAdvanceTime': Math.max(0, minAdvanceTime),
                });
            } else {
                await serviceEstab.update(estabSession._id, {
                    'agenda.daysOpen': daysOpen,
                    'agenda.scheduleGap': Math.max(10, scheduleGap),
                    'agenda.minAdvanceTime': Math.max(0, minAdvanceTime),
                });
            }

            successSnackbar('Agenda editada com sucesso!');
            reloadEstab();
        } catch (e) {
            errorSnackbar('Ocorreu um erro ao editar a agenda.');
        } finally {
            setLoading(false);
        }
    };

    const submitHorarios = async () => {
        try {
            setLoading(true);
            const validatedHorarios = validateHorarios(horarios);

            if (!!atendenteId) {
                await atendentesService.updateAgenda(atendenteId, {
                    'agenda.horarios': validatedHorarios,
                });
            } else {
                await serviceEstab.update(estabSession._id, {
                    'agenda.horarios': validatedHorarios,
                });
            }

            successSnackbar('Agenda editada com sucesso!');
            reloadEstab();
        } catch (e) {
            errorSnackbar('Ocorreu um erro ao editar a agenda.');
        } finally {
            setLoading(false);
        }
    };

    const submitAdm = async () => {
        try {
            setLoading(true);
            await serviceEstab.update(estabSession._id, {
                'agenda.disabled': disabled,
                'agenda.sendWhatsapp': sendWhatsapp,
                'agenda.requireApproval': requireApproval,
                'agenda.allowCancellation': allowCancellation,
                'agenda.minutesBeforeCancellation': minutesBeforeCancellation,
                'agenda.attendantConfigure': attendantConfigure,
                'agenda.requireConfirmation': requireConfirmation,
                'agenda.hoursBeforeConfirmation': hoursBeforeConfirmation,
            });

            successSnackbar('Agenda editada com sucesso!');
            reloadEstab();
        } catch (e) {
            errorSnackbar('Ocorreu um erro ao editar a agenda.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const load = async () => {
            if (!estabSession) return;

            const doc = {
                ...initialHorarios,
                ...(estabSession.agenda?.horarios || {}),
            };
            delete doc._id;

            const defaultAgenda = {
                daysOpen: estabSession.agenda?.daysOpen || 10,
                scheduleGap: estabSession.agenda?.scheduleGap || 10,
                minAdvanceTime: estabSession.agenda?.minAdvanceTime || 0,
                horarios: doc,
            };

            if (atendenteId) {
                try {
                    setLoading(true);
                    const { data } = await atendentesService.getAgenda(atendenteId);

                    setDaysOpen(data?.daysOpen ?? defaultAgenda.daysOpen);
                    setScheduleGap(data?.scheduleGap ?? defaultAgenda.scheduleGap);
                    setMinAdvanceTime(data?.minAdvanceTime ?? defaultAgenda.minAdvanceTime);
                    setHorarios(data?.horarios ?? defaultAgenda.horarios);
                } catch {
                    setDaysOpen(defaultAgenda.daysOpen);
                    setScheduleGap(defaultAgenda.scheduleGap);
                    setMinAdvanceTime(defaultAgenda.minAdvanceTime);
                    setHorarios(defaultAgenda.horarios);
                } finally {
                    setLoading(false);
                }
            } else {
                setDaysOpen(defaultAgenda.daysOpen);
                setScheduleGap(defaultAgenda.scheduleGap);
                setMinAdvanceTime(defaultAgenda.minAdvanceTime);
                setHorarios(defaultAgenda.horarios);
            }

            setHoursBeforeConfirmation(estabSession.agenda?.hoursBeforeConfirmation ?? 24);
            setRequireApproval(estabSession.agenda?.requireApproval ?? false);
            setAllowCancellation(estabSession.agenda?.allowCancellation ?? false);
            setMinutesBeforeCancellation(estabSession.agenda?.minutesBeforeCancellation ?? 0);
            setRequireConfirmation(estabSession.agenda?.requireConfirmation ?? false);
            setSendWhatsapp(estabSession.agenda?.sendWhatsapp ?? false);
            setDisabled(estabSession.agenda?.disabled ?? false);
            setAttendantConfigure(estabSession.agenda?.attendantConfigure ?? false);
        };

        load();
    }, [estabSession, atendenteId]);


    return (
        <AgendaSettingsContext.Provider
            value={{
                daysOpen,
                setDaysOpen,
                horarios,
                setHorarios,
                requireApproval,
                setRequireApproval,
                requireConfirmation,
                setRequireConfirmation,
                hoursBeforeConfirmation,
                setHoursBeforeConfirmation,
                sendWhatsapp,
                setSendWhatsapp,
                submitAdm,
                submitHorarios,
                submitGeral,
                loading,
                scheduleGap,
                setScheduleGap,
                minAdvanceTime,
                setMinAdvanceTime,
                attendantConfigure,
                setAttendantConfigure,
                disabled,
                setDisabled,
                atendenteId,
                setAtendenteId,
                allowCancellation,
                setAllowCancellation,
                minutesBeforeCancellation,
                setMinutesBeforeCancellation,
            }}
        >
            {children}
        </AgendaSettingsContext.Provider>
    );
};

const useAgendaSettingsContext = () => {
    const context = useContext(AgendaSettingsContext);
    if (context === undefined) {
        throw new Error('useAgendaSettingsContext must be used within an AgendaSettingsProvider');
    }
    return context;
};

export { AgendaSettingsProvider, useAgendaSettingsContext };
