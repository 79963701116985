import moment from 'moment';
import 'moment/locale/pt-br';
import { useAgendaContext } from './ContextAgenda';
import ScheduleItem from './ScheduleItem';
import ScheduleView from './view/ScheduleView';
import 'react-datepicker/dist/react-datepicker.css';
import { Modal } from '../../../components/Modal';
import { useParamsContext } from '../../../contexts/ParamsContext';
import { Loading } from '../../../components/Loading';
import React, { useEffect, useState } from 'react';
import { Scheduler, DayView, Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import { ViewState } from '@devexpress/dx-react-scheduler';
import { ExtraActionsAgenda } from './list/ExtraActionsAgenda';

moment.locale('pt-br');

export default function ScheduleList({ agenda }: any) {
    const { clear } = useParamsContext();
    const {
        date,
        schedules,
        selectedSchedule,
        loading,
        startDay,
        endDay,
        selectedRange,
    } = useAgendaContext();
    const [teste, setTeste] = useState<any>();

    useEffect(() => {
        if (!!selectedRange) {
            if (!teste) setTeste(selectedRange);
        } else {
            setTeste(null);
        }
    }, [selectedRange]);

    const appointments = schedules?.map(schedule => ({
        title: schedule?.client?.name || 'Sem nome',
        startDate: schedule?.startAt ? new Date(schedule.startAt) : new Date(),
        endDate: schedule?.endAt ? new Date(schedule.endAt) : new Date(),
        schedule,
    }));

    const CustomTimeTableCell = (props: any) => {
        const {
            date,
            startDay,
            endDay,
            selectedRange,
            settingsAgenda,
            setSelectedRange,
        } = useAgendaContext();

        const now = moment(date.toDate()).startOf('days');
        const startDate = moment(props.startDate).diff(now, 'minutes');
        const endDate = moment(props.endDate).diff(now, 'minutes');

        const isSelected = selectedRange && startDate >= selectedRange.start && endDate <= selectedRange.end;

        const isClosed = settingsAgenda?.closedSlots?.some(({ start, end }: { start: number; end: number }) => {
            return startDate >= start && endDate <= end;
        });

        const handleTimeSelection = () => {
            if (startDay * 60 >= startDate || (endDay - 0.151) * 60 < endDate) return;

            setSelectedRange(prev => {
                if (prev && startDate >= prev.start && endDate <= prev.end) {
                    if (prev.start >= endDate - 10) {
                        return undefined;
                    } else {
                        return {
                            start: Math.min(prev.start),
                            end: Math.max(endDate - 10),
                        };
                    }
                } else {
                    if (!prev) {
                        return { start: startDate, end: endDate };
                    }
                    return {
                        start: Math.min(prev.start, startDate),
                        end: Math.max(prev.end, endDate),
                    };
                }
            });
        };

        return (
            <DayView.TimeTableCell
                {...props}
                style={{ ...props.style, height: '57px' }}
            >
                <div
                    className={`relative w-full h-full ${isSelected ? 'bg-blue-300' : isClosed ? 'bg-red-300' : 'bg-transparent'}`}
                    onDoubleClick={handleTimeSelection}
                    onClick={() => {
                        if (!selectedRange) return;
                        handleTimeSelection();
                    }}
                >
                    {
                        selectedRange?.end === endDate &&
                        <div className="absolute z-10 left-0">
                            <ExtraActionsAgenda agenda={agenda} />
                        </div>
                    }
                    {props.children}
                </div>
            </DayView.TimeTableCell>
        );
    };

    const formatTimeScaleDate = (date: any) => (
        <span className="text-primary text-xs">{moment(date).format('HH:mm')}</span>
    );

    const TimeScaleLabel = ({ formatDate, ...restProps }: any) => (
        <DayView.TimeScaleLabel {...restProps} formatDate={formatTimeScaleDate}
                                style={{
                                    ...restProps.style,
                                    height: '57px',
                                    lineHeight: '57px',
                                    display: 'flex',
                                    paddingTop: '1px',
                                }} />
    );

    if (loading) {
        return <div className="w-full h-full items-center justify-center flex">
            <div className="text-center">
                <Loading />
            </div>
        </div>;
    }

    return (
        <div>
            <div className="w-full select-none">
                <Scheduler data={appointments}>
                    <ViewState currentDate={moment(date).toDate()} />
                    <DayView
                        cellDuration={10}
                        timeScaleLabelComponent={TimeScaleLabel}
                        timeTableCellComponent={CustomTimeTableCell}
                        startDayHour={startDay}
                        endDayHour={endDay}
                    />
                    <Appointments appointmentContentComponent={RenderAppointment} />
                </Scheduler>
            </div>

            {!!selectedSchedule && (
                <Modal onClose={clear} title="Agendamento">
                    <ScheduleView />
                </Modal>
            )}
        </div>
    );
}

const RenderAppointment = ({ data, ...restProps }: any) => {
    return (
        <Appointments.Appointment
            {...restProps}
            data={data}
            style={{
                border: 'none',
            }}
        >
            <ScheduleItem schedule={data.schedule} />
        </Appointments.Appointment>
    );
};


