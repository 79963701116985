import { useAgendaSettingsContext } from './Context';

export const PermissionsSchedule = () => {
    const {
        requireApproval,
        setRequireApproval,
        hoursBeforeConfirmation,
        setHoursBeforeConfirmation,
        requireConfirmation,
        setRequireConfirmation,
        setSendWhatsapp,
        sendWhatsapp,
        attendantConfigure,
        setAttendantConfigure,
        disabled,
        setDisabled,
        submitAdm,
        loading,
        allowCancellation,
        setAllowCancellation,
        minutesBeforeCancellation,
        setMinutesBeforeCancellation
    } = useAgendaSettingsContext();

    return (
        <div>
            {/* Aprovação do Atendente */}
            <div className="form-control mb-4">
                <label className="cursor-pointer label">
                    <span className="label-text">Aprovação do Atendente Necessária?</span>
                    <input type="checkbox" className="toggle toggle-primary"
                           checked={requireApproval}
                           onChange={(e) => setRequireApproval(e.target.checked)}
                    />
                </label>
                <span className="text-sm text-gray-500">
                    Se ativado, os agendamentos feitos pelo cliente precisarão ser aprovados manualmente pelo atendente antes da confirmação final.
                </span>
            </div>

            <div className="divider" />

            {/* Configuração da Agenda pelo Atendente */}
            <div className="form-control mb-4">
                <label className="cursor-pointer label">
                    <span className="label-text">O atendente pode configurar sua própria agenda?</span>
                    <input type="checkbox" className="toggle toggle-primary"
                           checked={attendantConfigure}
                           onChange={(e) => setAttendantConfigure(e.target.checked)}

                    />
                </label>
                <span className="text-sm text-gray-500">
                    Se ativado, cada atendente poderá definir sua disponibilidade e preferências de agendamento.
                </span>
            </div>

            <div className="divider" />

            {/* Permitir Cancelamento */}
            <div className="form-control mb-4">
                <label className="cursor-pointer label">
                    <span className="label-text">Permitir que o cliente cancele o agendamento?</span>
                    <input type="checkbox" className="toggle toggle-primary"
                           checked={allowCancellation}
                           onChange={(e) => setAllowCancellation(e.target.checked)}
                    />
                </label>
                <span className="text-sm text-gray-500 mb-2">
                    Se ativado, o cliente poderá cancelar o agendamento dentro do prazo especificado.
                </span>
            </div>

            {allowCancellation && (
                <div className="form-control mb-4">
                    <label className="label" htmlFor="minutesBeforeCancellation">
                        <span className="label-text">Quantos minutos antes o cliente pode cancelar?</span>
                    </label>
                    <input type="number" id="minutesBeforeCancellation"
                           value={minutesBeforeCancellation}
                           onChange={(e) => setMinutesBeforeCancellation(parseInt(e.target.value))}
                           className="input input-bordered" min="1" />
                </div>
            )}

            <div className="divider" />

            <div className="form-control mb-4">
                <label className="cursor-pointer label">
                    <span className="label-text">Solicitar Confirmação de Agendamento?</span>
                    <input
                        type="checkbox"
                        className="toggle toggle-primary"
                        checked={requireConfirmation}
                        onChange={(e) => setRequireConfirmation(e.target.checked)}
                    />
                </label>
                <span className="text-sm text-gray-500 mb-2">
                    Quando ativado, o cliente será solicitado a confirmar o agendamento. Essa confirmação pode ajudar a
                    reduzir cancelamentos de última hora.
                </span>
            </div>

            {requireConfirmation && (
                <div className="form-control mb-4">
                    <label className="label" htmlFor="hoursBeforeConfirmation">
                        <span className="label-text">Tempo antes do agendamento para confirmação (em horas)</span>
                    </label>
                    <input
                        type="number"
                        id="hoursBeforeConfirmation"
                        value={hoursBeforeConfirmation}
                        onChange={(e) => setHoursBeforeConfirmation(parseInt(e.target.value))}
                        className="input input-bordered"
                        min="1"
                    />
                    <span className="text-sm text-gray-500 mb-2">
                        Especifique o número de horas antes do horário agendado em que o cliente será solicitado a
                        confirmar o agendamento.
                    </span>
                </div>
            )}

            {requireConfirmation && (
                <div className="form-control mb-4">
                    <label className="cursor-pointer label">
                        <span className="label-text">Enviar Confirmação via WhatsApp?</span>
                        <input
                            type="checkbox"
                            checked={sendWhatsapp}
                            onChange={(e) => setSendWhatsapp(e.target.checked)}
                            className="toggle toggle-primary"
                        />
                    </label>
                    <span className="text-sm text-gray-500 mb-2">
                        Se ativado, uma mensagem será enviada pelo WhatsApp solicitando a confirmação do agendamento por
                        parte do cliente.
                    </span>
                </div>
            )}

            {/*{requireConfirmation && (*/}
            {/*    <div className="form-control mb-4">*/}
            {/*        <label className="cursor-pointer label">*/}
            {/*            <span className="label-text">Enviar Confirmação via Email?</span>*/}
            {/*            <input*/}
            {/*                type="checkbox"*/}
            {/*                checked={sendEmail}*/}
            {/*                onChange={(e) => setSendEmail(e.target.checked)}*/}
            {/*                className="toggle toggle-primary"*/}
            {/*            />*/}
            {/*        </label>*/}
            {/*        <span className="text-sm text-gray-500 mb-2">*/}
            {/*            Se ativado, uma mensagem será enviada pelo Email solicitando a confirmação do agendamento por*/}
            {/*            parte do cliente.*/}
            {/*        </span>*/}
            {/*    </div>*/}
            {/*)}*/}

            <div className="divider" />

            <div className="form-control mb-4">
                <label className="cursor-pointer label">
                    <span className="label-text">Desativar todas as agendas?</span>
                    <input type="checkbox" className="toggle toggle-primary"
                           checked={disabled}
                           onChange={(e) => setDisabled(e.target.checked)}
                    />
                </label>
                <span className="text-sm text-gray-500">
                    Se ativado, os clientes não poderão agendar novos horários com nenhum atendente até que esta opção seja desativada.
                </span>
            </div>

            <div className="divider" />

            <button className="btn shadow-md" type="submit" onClick={submitAdm} disabled={loading}>
                Salvar Alterações
            </button>
        </div>
    );
};
