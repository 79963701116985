import { api } from '../../AxiosConfig';

export class VerificationCodeService {
    getByAction(action: string) {
        return api.get(`/verification-code/get-by-action/${action}`)
    }

    delete(verificationId: string) {
        return api.delete(`/verification-code/${verificationId}`)
    }
}