import { useState } from 'react';
import { UserService } from '../modules/configuracoes/usuario/Service';
import { useAppContext } from '../contexts/AppContext';

const service = new UserService();

export default function RecoverPassword({ setRecoverPass }: any) {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const { errorSnackbar, successSnackbar } = useAppContext();

    const onSubmitRequest = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setLoading(true);

        try {
            await service.requestRecoverPassword(email);
            successSnackbar('Email enviado com instruções para restaurar senha!');
            setRecoverPass(false);
        } catch (error: any) {
            errorSnackbar('Ocorreu um erro ao tentar recuperar a senha.');
        } finally {
            setLoading(false);
        }
    };

    return <form className="space-y-4 md:space-y-6" onSubmit={onSubmitRequest} key={1}>
        <div className="flex items-center" onClick={() => setRecoverPass(false)}>
            <button className="btn btn-xs btn-circle mr-2">
                <i className="fa-solid fa-angle-left"></i>
            </button>
            Recuperar Senha
        </div>
        <div>
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Email
            </label>
            <input
                type="email"
                name="email"
                id="email"
                disabled={loading}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="name@company.com"
                required
            />
        </div>
        <button
            type="submit"
            disabled={loading}
            className="w-full h-10 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-black text-white"
        >
            {loading ? <span className="loading loading-bars loading-sm" /> : 'Continuar'}
        </button>
    </form>;
}
