import moment from 'moment';
import 'moment/locale/pt-br';
import { useAgendaContext } from '../ContextAgenda';
import { useParamsContext } from '../../../../contexts/ParamsContext';
import ScheduleViewHeader from './ScheduleViewHeader';
import { getInitialName } from '../../../../components/utils';
import { Histories } from '../../../../history/Historys';
import { baseURL } from '../../../../AxiosConfig';

moment.locale('pt-br');

export default function ScheduleView() {
    const { selectedSchedule: schedule } = useAgendaContext();

    return (
        <div>
            {schedule && <ScheduleViewHeader schedule={schedule} />}

            <div role="tablist" className="tabs tabs-bordered mt-3">
                <input type="radio" name="my_tabs_1" role="tab" className="tab" aria-label="Geral" defaultChecked />
                <div role="tabpanel" className="tab-content">
                    <div className="overflow-x-auto">
                        <table className="table">
                            <tbody>
                            <tr className="hover">
                                <th>Atendente</th>
                                <td>
                                    <div className="flex items-center gap-3">
                                        <div className="avatar h-12 w-12">
                                            <div className="bg-neutral text-neutral-content mask mask-squircle flex items-center justify-center h-full w-full overflow-hidden">
                                                <img
                                                    alt="Foto do Cliente"
                                                    src={`${baseURL}/users/profileImage/${schedule?.attendant.uid}`}
                                                    className="h-full w-full object-cover"
                                                    loading="lazy"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="font-bold">{schedule?.attendant.name}</div>

                                            {schedule?.attendant?.phoneNumber ? (
                                                <a
                                                    href={`https://wa.me/${schedule?.attendant.phoneNumber.replace(/\D/g, '')}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <button
                                                        className="btn btn-circle btn-xs flex items-center justify-center text-white bg-green-500 hover:bg-green-600"
                                                    >
                                                        <i className="fa-brands fa-whatsapp"></i>
                                                    </button>
                                                </a>
                                            ) : (
                                                <button
                                                    className="btn btn-circle btn-xs flex items-center justify-center text-white bg-gray-400 cursor-not-allowed"
                                                    disabled
                                                >
                                                    <i className="fa-brands fa-whatsapp"></i>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr className="hover">
                                <th>Serviço</th>
                                <td>
                                    {schedule?.service.name}
                                    <br />
                                    {schedule?.service?.price
                                        ? new Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                        }).format(schedule.service.price)
                                        : 'R$ 0,00'}
                                </td>
                            </tr>

                            {!!schedule?.cancellation?.date && (
                                <tr className="hover">
                                    <th>Cancelamento</th>
                                    <td>
                                        <div className="">
                                            <div>{schedule?.cancellation?.observation}</div>
                                            <div className="text-xs">
                                                {moment(schedule?.cancellation?.date).format('DD/MM/YYYY HH:mm')}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )}

                            <tr className="hover">
                                <th>Agendado em</th>
                                <td>{moment(schedule?.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <input type="radio" name="my_tabs_1" role="tab" className="tab" aria-label="Histórico" />
                <div role="tabpanel" className="tab-content pt-5">
                    <div className="overflow-x-auto">
                        <Histories resourceId={schedule?._id} />
                    </div>
                </div>
            </div>
        </div>
    );
}
