import { api } from '../../AxiosConfig';

class AtendentesService {
    create(data: any) {
        return api.post('users/atendentes/create', data);
    }

    getAll() {
        return api.get('users/atendentes/getAll');
    }

    getByEstabId(estabId: string, serviceId?: string) {
        return api.get(`users/atendentes/getByEstabId/${estabId}/${serviceId}`);
    }

    delete(uid: string) {
        return api.post('users/atendentes/delete', { uid });
    }

    update(uid: string, update: {
        admin: string
    }) {
        return api.post('users/atendentes/update', { uid, update });
    }

    updateAgenda(atendenteId: string, update: any) {
        return api.post('users/atendentes/updateAgenda', { atendenteId, update });
    }

    getAgenda(atendenteId: string) {
        return api.get(`users/atendentes/agenda/${atendenteId}`);
    }

    updateAgendaDate({ atendenteId, update, date }: { atendenteId: string, update: any, date: string }) {
        return api.post('users/atendentes/updateAgendaDate', { atendenteId, date, update });
    }

    addClosedSlots({ atendenteId, date, closedSlots }: {
        date: string
        atendenteId: string,
        closedSlots: { start: number; end: number },
    }) {
        return api.post('users/atendentes/addClosedSlots', { atendenteId, date, closedSlots });
    }

    getAgendaDate(atendenteId: string, date: string, estab?: string) {
        return api.get(`users/atendentes/agendaDate/${atendenteId}/${date}/${estab}`);
    }

    getAgendaList(atendenteId: string) {
        return api.get(`users/atendentes/agendaList/${atendenteId}`);
    }
}

export { AtendentesService };
