import { useEffect, useState } from 'react';
import { useEstabsContext } from '../../../contexts/EstabsContext';
import { urlFile } from '../../../AxiosConfig';
import { ToggleAddEstabUserBtn } from '../client/AddEstabUser';
import { ViewEstabInfoModal } from './ViewEstabInfoModal';
import { EstabelecimentosService } from '../system-admin/Service';
const estabsService = new EstabelecimentosService();

export const CardEstab = ({ estabId }: any) => {
    const [estab, setEstab] = useState<any>({});
    const { estabsUser } = useEstabsContext();
    const [openInfoEstab, setOpenInfoEstab] = useState(false);

    const loadEstab = async () => {
        try {
            const { data } = await estabsService.getInfoById(estabId);

            setEstab(data);
        } catch (err) {
        }
    };
    useEffect(() => {
        loadEstab();
    }, [estabId]);

    return (
        <div className="card bg-base-100 shadow-xl hover:shadow-2xl">
            <div onClick={() => setOpenInfoEstab(true)} className="cursor-pointer">
                {estab?.imageId && (
                    <figure className="p-4">
                        <img src={`${urlFile}/${estab?.imageId}`} alt="Shoes" />
                    </figure>
                )}
                <div className="card-body">
                    <h2 className="card-title">
                        {estab?.name}
                        <div className="badge badge-primary">NEW</div>
                    </h2>
                    <p>{estab?.description}</p>

                    <p className="text-xs">
                        {[
                            estab?.address?.street,
                            estab?.address?.number,
                            estab?.address?.complement,
                            estab?.address?.neighborhood,
                            estab?.address?.city,
                            estab?.address?.state,
                            estab?.address?.zipCode,
                        ]
                            .filter(Boolean)
                            .join(', ')}
                    </p>
                </div>
            </div>

            <div className="flex justify-between items-center px-5 pb-5">
                {estabsUser.some((e) => e._id === estab._id && e.type === 'client') ? (
                    <button className="btn btn-sm btn-primary">AGENDAR HORÁRIO</button>
                ) : (
                    <ToggleAddEstabUserBtn estabId={estab._id} />
                )}

                <div className="card-actions" style={{ alignItems: 'center' }}>
                    {estab?.phoneNumber && estab?.isPhoneNumberWhatsapp ? (
                        <a
                            href={`https://wa.me/${estab?.phoneNumber.replace(/\D/g, '')}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <button
                                className="btn btn-xs flex items-center justify-center text-white bg-green-500 hover:bg-green-600"
                            >
                                <i className="fa-brands fa-whatsapp"></i>
                            </button>
                        </a>
                    ) : (
                        <button
                            className="btn btn-xs flex items-center justify-center text-white bg-gray-400 cursor-not-allowed"
                            disabled
                        >
                            <i className="fa-brands fa-whatsapp"></i>
                        </button>
                    )}

                    {estab?.location ? (
                        <a
                            href={estab?.location}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <button
                                className="btn btn-xs flex items-center justify-center text-white"
                            >
                                <i className="fa-solid fa-location-dot"></i>
                            </button>
                        </a>
                    ) : (
                        <button
                            className="btn btn-xs flex items-center justify-center text-white bg-gray-400 cursor-not-allowed"
                            disabled
                        >
                            <i className="fa-solid fa-location-dot"></i>
                        </button>
                    )}

                    {estabsUser.some((e) => e._id === estab._id) && <ToggleAddEstabUserBtn estabId={estab._id} small />}
                </div>
            </div>

            {openInfoEstab && <ViewEstabInfoModal estabId={estab._id} onClose={() => setOpenInfoEstab(false)} />}
        </div>
    );
};
