import { useState } from 'react';
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useAppContext } from '../../../../contexts/AppContext';
import { UserService } from '../Service';

const service = new UserService();

export default function ConfiguracoesContaPassword() {
    const { successSnackbar, errorSnackbar } = useAppContext();

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const { user } = useAuthContext();
    const auth = getAuth();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            errorSnackbar('As novas senhas não coincidem.');
            return;
        }

        if (user) {
            setLoading(true);
            try {
                const userCredential = EmailAuthProvider.credential(user.email!, currentPassword);
                await reauthenticateWithCredential(auth.currentUser!, userCredential);
                await updatePassword(auth.currentUser!, newPassword);
                successSnackbar('Senha atualizada com sucesso!');

                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
            } catch (error: any) {
                errorSnackbar('Erro ao atualizar a senha.');
            } finally {
                setLoading(false);
            }
        }
    };

    const recoverPassword = async () => {
        try {
            if (!!user?.email) {
                await service.requestRecoverPassword(user?.email);
            }

            successSnackbar('Email enviado com instruções para restaurar senha!');
        } catch (e) {
            errorSnackbar('Erro ao enviar email para redefinir senha.');
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div>
                    <label className="label" htmlFor="currentPassword">
                        <span className="label-text">Senha atual:</span>
                    </label>
                    <input
                        type="password"
                        id="currentPassword"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                        className="input input-bordered w-full max-w-96"
                    />
                    <label className="label" htmlFor="currentPassword">
                        <a
                            href="#"
                            onClick={recoverPassword}
                            className="text-sm hover:underline"
                        >
                            Esqueceu sua senha?
                        </a>
                    </label>
                </div>

                <div className="divider"></div>

                <div>
                    <label className="label" htmlFor="newPassword">
                        <span className="label-text">Nova senha:</span>
                    </label>
                    <input
                        type="password"
                        id="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                        className="input input-bordered w-full max-w-96"
                    />
                </div>

                <div>
                    <label className="label" htmlFor="confirmPassword">
                        <span className="label-text">Confirme a nova senha:</span>
                    </label>
                    <input
                        type="password"
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        className="input input-bordered w-full max-w-96"
                    />
                </div>

                <div className="divider" />

                <button className="btn shadow-md" type="submit" disabled={loading}>
                    {loading ? 'Salvando...' : 'Salvar'}
                </button>
            </form>
        </div>
    );
}
