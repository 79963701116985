import React from 'react';
import { useFormHorarioClienteContext } from './Context';
import MultiSelect from '../../../../components/inputs/MultiSelect';

const SelectClient = () => {
    const { clientId, setClientId, clients, loading } = useFormHorarioClienteContext();

    return (
        <MultiSelect
            options={clients}
            loading={loading}
            value={clientId ? [clientId] : []}
            onChange={selected => setClientId(selected as string)}
            placeholder="Selecione o cliente"
            label="Cliente"
            name="cliente"
            multiple={false}
        />
    );
};

export default SelectClient;
