import { Route, Routes, Navigate } from 'react-router-dom';
import PageLoading from './components/PageLoading';
import { useAppContext } from './contexts/AppContext';
import { useAuthContext } from './contexts/AuthContext';
import DashboardPage from './dashboard/Dashboard';
import LoginPage from './login/Login';
import VerificationAccount from './verificationAccount/VerificationAccount';
import { PoliticaPrivacidade } from './PoliticaPrivacidade';
import { Footer } from './components/Footer';
import { TermosUso } from './TermosUso';
import { brandingEstabId } from './branding';
import {LandingPage} from "./langing-page/LandingPage";
import RestorePassword from './restorePassword/RestorePassword';
import VerificationAccountWhatsapp from './verificationAccountWhatsapp/VerificationAccountWhatsapp';

export default function App() {
    const { user } = useAuthContext();
    const { loadingApp } = useAppContext();

    if (loadingApp) {
        return (
            <div className="h-screen">
                <PageLoading />
                <Footer />
            </div>
        );
    }

    return (
        <div className="h-screen">
            <main className="flex-grow">
                <Routes>
                    <Route path="/verification-whatsapp-account/:code/:uid" element={<VerificationAccountWhatsapp />} />
                    <Route path="/verification-account" element={<VerificationAccount />} />
                    <Route path="/restore-password" element={<RestorePassword />} />
                    <Route path="/politicaDePrivacidade" element={<PoliticaPrivacidade />} />
                    <Route path="/termosDeUso" element={<TermosUso />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/page" element={<LandingPage brandingEstabId={brandingEstabId} />} />
                    <Route path="/page/:pageName" element={<LandingPage brandingEstabId={brandingEstabId} />} />

                    <Route
                        path="*"
                        element={
                            user?.uid ? (
                                <DashboardPage />
                            ) : brandingEstabId ? (
                                <LandingPage brandingEstabId={brandingEstabId} />
                            ) : (
                                <Navigate to="/login" replace />
                            )
                        }
                    />
                </Routes>
            </main>
        </div>
    );
}
