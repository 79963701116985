import React, { createContext, ReactNode, useContext, useState } from 'react';
import moment from 'moment';

interface DashboardContextType {
    competence: string;
    setCompetence: (competence: string) => void;
    atendenteId: string;
    setAtendenteId: (atendenteId: string) => void;
}

const DashboardContext = createContext<DashboardContextType | undefined>(undefined);

export const DashboardProvider = ({ children }: { children: ReactNode }) => {
    const [atendenteId, setAtendenteIdState] = useState<string>(() => {
        const stored = sessionStorage.getItem('dashboardAtendenteId');
        return stored || '';
    });

    const [competence, setCompetenceState] = useState<string>(() => {
        const stored = sessionStorage.getItem('dashboardCompetence');
        return stored || moment().format('YYYY-MM');
    });

    const setAtendenteId = (id: string) => {
        setAtendenteIdState(id);
        sessionStorage.setItem('dashboardAtendenteId', id);
    };

    const setCompetence = (comp: string) => {
        setCompetenceState(comp);
        sessionStorage.setItem('dashboardCompetence', comp);
    };

    return (
        <DashboardContext.Provider value={{ competence, setCompetence, atendenteId, setAtendenteId }}>
            {children}
        </DashboardContext.Provider>
    );
};

export const useDashboardContext = (): DashboardContextType => {
    const context = useContext(DashboardContext);
    if (!context) {
        throw new Error('useDashboardContext must be used within a DashboardProvider');
    }
    return context;
};
