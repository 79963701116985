import { ActionsService } from './Actions';
import StatusService from './Status';

export const columns = [
    {
        name: 'Situação',
        width: '110px',
        cell: (row: any) => <StatusService id={row._id} status={row.status} />,
        style: { whiteSpace: 'nowrap' },
    },
    {
        name: 'Nome',
        sortable: true,
        cell: (row: any) => row.name,
        style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
    },
    {
        name: 'Ações',
        sortable: false,
        width: '190px',
        cell: (row: any) => <ActionsService row={row} />,
    },
];

