import { useAgendaSettingsContext } from './Context';
import { dayLabels, minutesToTime, timeToMinutes } from '../../Enum';
import SelectAtendentes from '../../../../components/inputs/SelectAtendentes';
import { useAuthContext } from '../../../../contexts/AuthContext';

export default function AgendaSettingsHorarios() {
    const { horarios, setHorarios, submitHorarios, loading, atendenteId, setAtendenteId } = useAgendaSettingsContext();

    const handleAddSlot = (day: string) => {
        const newHorarios = { ...horarios };
        newHorarios[day].push({ start: 0, end: 0 });
        setHorarios(newHorarios);
    };

    const handleSlotChange = (day: string, slotIndex: number, field: 'start' | 'end', value: string) => {
        const newHorarios = { ...horarios };
        newHorarios[day][slotIndex][field] = timeToMinutes(value);
        setHorarios(newHorarios);
    };

    const handleRemoveSlot = (day: string, slotIndex: number) => {
        const newHorarios = { ...horarios };
        newHorarios[day].splice(slotIndex, 1);
        setHorarios(newHorarios);
    };


    return (
        <div className="p-4">
            {Object.entries(horarios)?.map(([day, horario], i, array) => {
                return (
                    <div key={day} className={array.length -1 !== i ? 'pb-6 mb-5 border-b border-gray-300' : ''}>
                        <h3 className="text-xl font-semibold mb-2">{dayLabels?.[day]}</h3>
                        {horario?.map((slot, slotIndex) => (
                            <div key={slotIndex} className="flex items-center gap-4 mb-2">
                                <input
                                    type="time"
                                    value={minutesToTime(slot.start)}
                                    onChange={(e) => handleSlotChange(day, slotIndex, 'start', e.target.value)}
                                    className="input input-bordered input-xs md:input-md"
                                />
                                <input
                                    type="time"
                                    value={minutesToTime(slot.end)}
                                    onChange={(e) => handleSlotChange(day, slotIndex, 'end', e.target.value)}
                                    className="input input-bordered input-xs md:input-md"
                                />
                                <button
                                    type="button"
                                    disabled={loading}
                                    onClick={() => handleRemoveSlot(day, slotIndex)}
                                    className="btn btn-error btn-xs md:btn-sm"
                                >
                                    <i className="fa-solid fa-trash"></i>
                                </button>
                            </div>
                        ))}
                        <button
                            type="button"
                            disabled={loading}
                            onClick={() => handleAddSlot(day)}
                            className="btn btn-sm"
                        >
                            Adicionar Intervalo
                        </button>
                    </div>
                );
            })}

            <div className="divider" />

            <button onClick={submitHorarios} disabled={loading} className="btn shadow-md" type="submit">
                Salvar Alterações
            </button>
        </div>
    );
}
