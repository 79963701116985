import CustomDataTable from '../../components/table/Table';
import { UserService } from '../configuracoes/usuario/Service';
import React, { useEffect, useState } from 'react';
import AvatarUser from '../../components/AvatarUser';
import { Modal } from '../../components/Modal';
import { Loading } from '../../components/Loading';
import { useClientsContext } from './Context';

const userService = new UserService();

interface DataRow {
    uid: string;
    displayName: string;
    email: string;
    photoURL: string;
    phoneNumber: string;
    disabled: boolean;
    type: string;
    emailVerified: boolean;
}

export default function ClientsList() {
    const { data, loading, loadClients } = useClientsContext();

    const columns = [
        {
            name: 'Nome',
            selector: (row: DataRow) => row.displayName,
            sortable: true,
            grow: 1, // Faz a coluna ocupar o máximo de espaço disponível
            cell: (row: DataRow) => (
                <div className="flex items-center">
                    <AvatarUser photoURL={row.photoURL} name={row.displayName || row.email} />
                    <div className="ml-2">{row.displayName}</div>
                </div>
            ),
        },
        {
            name: 'Ações',
            right: true,
            sortable: false,
            width: '80px',
            minWidth: '80px',
            cell: (row: DataRow) => <Actions row={row} />,
        },
    ];

    useEffect(() => {
        loadClients();
    }, []);

    return (
        <CustomDataTable
            loading={loading}
            columns={columns}
            data={data}
            pagination
            highlightOnHover
            striped
            expandableRowsComponent={ViewClient}
        />
    );
}

const ViewClient: React.FC<{ data: DataRow }> = ({ data }) => {
    const { loadClients } = useClientsContext();
    const [loading, setLoading] = useState(false);
    const [openRemove, setOpenRemove] = useState(false);

    const removeClient = async () => {
        try {
            setLoading(true);
            await userService.removeClient(data.uid);
            loadClients();
        } catch (e) {
            console.error('Erro ao remover cliente:', e);
        } finally {
            setLoading(false);
            setOpenRemove(false);
        }
    };

    return (
        <div className="p-4 border-b border-gray-200 text-sm">
            <h3 className="font-semibold border-b border-gray-200 text-lg pb-1 mb-1">Detalhes do Cliente</h3>
            <p><strong>Nome:</strong> {data.displayName}</p>
            <p><strong>Email:</strong> {data.email}</p>
            <p><strong>Telefone:</strong> {data.phoneNumber}</p>
            <p><strong>Status:</strong> {data.disabled ? 'Desativado' : 'Ativo'}</p>
            <p><strong>Email Verificado:</strong> {data.emailVerified ? 'Sim' : 'Não'}</p>

            <div className="border-t border-gray-200 pt-2 mt-1">
                <button
                    className="btn btn-sm btn-error"
                    onClick={() => setOpenRemove(true)}
                >
                    Remover
                </button>
            </div>

            {openRemove && (
                <Modal onClose={() => setOpenRemove(false)} title="Remover Cliente"
                       subTitle="Deseja realmente remover este cliente?">
                    <div className="flex justify-between">
                        <button className="btn btn-ghost" onClick={() => setOpenRemove(false)} disabled={loading}>
                            Cancelar
                        </button>
                        <button className="btn btn-primary" onClick={removeClient} disabled={loading}>
                            {loading ? <Loading /> : 'Confirmar'}
                        </button>
                    </div>
                </Modal>
            )}
        </div>
    );
};

const Actions = ({ row }: { row: DataRow }) => {
    const openWhatsApp = () => {
        if (row.phoneNumber) {
            const url = `https://wa.me/${row.phoneNumber}`;
            window.open(url, '_blank');
        }
    };

    return (
        <div className="flex gap-2">
            {row.phoneNumber ? (
                <a
                    href={`https://wa.me/${row.phoneNumber.replace(/\D/g, '')}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <button
                        className="btn btn-square btn-xs flex items-center justify-center text-white bg-green-500 hover:bg-green-600"
                    >
                        <i className="fa-brands fa-whatsapp"></i>
                    </button>
                </a>
            ) : (
                <button
                    className="btn btn-square btn-xs flex items-center justify-center text-white bg-gray-400 cursor-not-allowed"
                    disabled
                >
                    <i className="fa-brands fa-whatsapp"></i>
                </button>
            )}
        </div>
    );
};
