import { AgendaEstabelecimentoDate } from './List';
import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import ScheduleList from '../ScheduleList';
import { AgendaProvider, useAgendaContext } from '../ContextAgenda';
import { Modal } from '../../../../components/Modal';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useEstabsContext } from '../../../../contexts/EstabsContext';
import { DateItemSettings } from './ItemSettings';
import { DateItemActions } from './ItemActions';
import { ExtraActionsAgenda } from './ExtraActionsAgenda';

interface DateItemProps {
    index: number;
    agenda: AgendaEstabelecimentoDate;
}

export const DateItem = ({ agenda, index }: DateItemProps) => {
    const { user } = useAuthContext();
    const { estabSession } = useEstabsContext();
    const [open, setOpen] = useState(index === 0);
    const [openConfig, setOpenConfig] = useState<boolean>(false);
    const [isNearTop, setIsNearTop] = useState(false);
    const headerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleScroll = () => {
            if (headerRef.current) {
                const rect = headerRef.current.getBoundingClientRect();
                setIsNearTop(open && rect.top <= 50);
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [open]);

    const Header = () => (
        <div
            onClick={() => setOpen(!open)}
            className={`flex items-center gap-2 text-sm font-medium overflow-x-auto py-3 cursor-pointer w-full px-2 sm:px-5`}
        >
            {open ? <i className="fa-solid fa-chevron-up"></i> : <i className="fa-solid fa-chevron-down"></i>}
            <div className="min-w-32">
                <span className="font-bold text-lg text-primary">
                    {moment(agenda.date).format('dddd')}
                </span>
                <br />
                <span className="text-xs">
                    {moment(agenda.date).format('DD [de] MMMM')}
                </span>
            </div>

            {
                !!agenda?.agora && <div className="border-gray-200 border-l-2 p-2 text-[12px] hidden sm:block">
                    <span className="font-bold flex items-center gap-1 h-min p-0 m-0">
                        Agora
                        <span className="relative size-3 flex">
                          <span
                              className="absolute inline-flex h-full w-full animate-ping rounded-full bg-sky-400 opacity-75"></span>
                          <span className="relative inline-flex size-3 rounded-full bg-sky-500"></span>
                        </span>
                    </span>
                    <span>{agenda?.agora.horario} | {agenda?.agora.servico}</span>
                </div>
            }

            {
                !!agenda?.proximo && <div className="border-gray-200 border-l-2 p-2 text-[12px] hidden sm:block">
                    <span className="font-bold">Próximo</span>
                    <br />
                    <span>{agenda?.proximo.horario} | {agenda?.proximo.servico}</span>
                </div>
            }

            <div className="border-gray-200 border-l-2 p-2 text-[12px]">
                <span className="font-bold">Total</span>
                <br />
                <span>{agenda.total}</span>
            </div>

            {
                (user?.root || user?.admin || estabSession?.agenda?.attendantConfigure) &&
                <DateItemActions setOpenConfig={setOpenConfig} />
            }
        </div>
    );

    return (
        <div className="border border-gray-300 rounded-lg">
            <div ref={headerRef} className={`${isNearTop && 'opacity-0'}`}>
                <Header />
            </div>

            {
                isNearTop && <div className={`fixed top-[58px] bg-white z-30 bg-opacity-75 backdrop-blur-lg`} style={{
                    width: `${headerRef?.current?.offsetWidth}px`,
                }}>
                    <Header />
                </div>
            }

            <div className={`${!open && 'hidden'} px-2`}>
                {open && <ScheduleList agenda={agenda}/>}
            </div>

            {openConfig && (
                <Modal onClose={() => setOpenConfig(false)}
                       title={moment(agenda.date).format('dddd, DD [de] MMMM [de] YYYY')}>
                    <DateItemSettings date={agenda.date} onClose={() => setOpenConfig(false)} />
                </Modal>
            )}
        </div>
    );
};
