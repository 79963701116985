import React from 'react';
import { Copy, Mail } from 'lucide-react';
import toast from 'react-hot-toast';
import { useEstabsContext } from '../../contexts/EstabsContext';

export const ShareAgenda: React.FC = () => {
    const { estabSession } = useEstabsContext();
    const shareUrl = `${window.location.origin}/login?estabId=${estabSession._id}`;

    const handleCopyLink = async () => {
        try {
            await navigator.clipboard.writeText(shareUrl);
            toast.success('Link copiado!');
        } catch (err) {
            toast.error('Erro ao copiar link');
        }
    };

    const handleEmailShare = () => {
        const subject = encodeURIComponent('Confira minha agenda!');
        const body = encodeURIComponent(`Olá! Confira minha agenda em: ${shareUrl}`);
        window.location.href = `mailto:?subject=${subject}&body=${body}`;
    };

    return (
        <div className="space-y-4">
            <button
                onClick={handleCopyLink}
                className="btn w-full flex items-center gap-2 hover:bg-gray-100"
            >
                <Copy className="w-5 h-5" />
                Copiar Link
            </button>

            <button
                onClick={handleEmailShare}
                className="btn w-full flex items-center gap-2 hover:bg-gray-100"
            >
                <Mail className="w-5 h-5" />
                Compartilhar por Email
            </button>

            <div>
                <a
                    href={`https://wa.me/?text=Confira minha agenda em: ${shareUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <button
                        className="btn btn-success w-full flex items-center gap-2 text-white"
                    >
                        <i className="fa-brands fa-whatsapp text-lg"></i>

                        Compartilhar no WhatsApp
                    </button>
                </a>
            </div>
        </div>
    );
};
