import { useParamsContext } from '../../../contexts/ParamsContext';
import AgendaSettings from './settings/Settings';
import { AgendaEstabelecimentoList } from './list/List';
import { ListAgendaProvider } from './list/Context';
import { AgendaForm } from './Form/Form';

const Agenda = () => {
    const { action } = useParamsContext();

    switch (action) {
        case 'add': {
            return <div className="pb-10"><AgendaForm /></div>;
        }
        case 'settings': {
            return <div className="pb-10"><AgendaSettings /></div>;
        }
        default:
            return (
                <div>
                    <ListAgendaProvider>
                        <AgendaEstabelecimentoList />
                    </ListAgendaProvider>
                </div>
            );
    }
};

export default Agenda;
