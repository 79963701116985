import React, { createContext, useContext, useState } from 'react';
import { useAuthContext } from '../../../../contexts/AuthContext';

interface Params {
    attendantId: string;
    setAttendantId: (attendantId: string) => void;
}

const Context = createContext<Params | undefined>(undefined);

export const useListAgendaContext = () => {
    const context = useContext(Context);
    if (!context) {
        throw new Error('ListAgenda must be used within a ListAgendaProvider');
    }
    return context;
};

export const ListAgendaProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { user } = useAuthContext();

    const defaultAttendantId = (!(user?.admin || user?.root) && user?.uid) ? user.uid : '';

    const [attendantId, setAttendantIdState] = useState<string>(() => {
        const stored = sessionStorage.getItem('attendantId');
        return stored || defaultAttendantId;
    });

    const setAttendantId = (id: string) => {
        setAttendantIdState(id);
        sessionStorage.setItem('attendantId', id);
    };

    return (
        <Context.Provider value={{ attendantId: attendantId || (user?.uid as string), setAttendantId }}>
            {children}
        </Context.Provider>
    );
};
