import { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface ModalProps {
    children: ReactNode;
    title: string;
    subTitle?: ReactNode;
    onClose: () => void;
    transparent?: boolean;
}

export const Modal = ({
                          title,
                          subTitle,
                          children,
                          onClose,
                          transparent = true,
                      }: ModalProps) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkScreenSize = () => {
            setIsMobile(window.innerWidth < 500);
        };

        checkScreenSize();
        window.addEventListener('resize', checkScreenSize);

        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);

    const handleClickOutside = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    return createPortal(
        <div
            className={` ${transparent ? 'backdrop-blur-sm bg-transparent' : 'bg-white bg-opacity-5'}`}
            onClick={handleClickOutside}
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                backgroundColor: transparent ? 'rgba(0, 0, 0, 0.5)' : '',
                display: 'flex',
                justifyContent: isMobile ? 'flex-start' : 'center',
                alignItems: isMobile ? 'flex-start' : 'center',
                zIndex: 1000,
                padding: isMobile ? 0 : '20px',
            }}
        >
            <div
                className={`relative  ${isMobile ? 'w-full h-full' : 'p-3 rounded-lg'}`}
                style={isMobile ? {
                    minWidth: '100vw',
                    minHeight: '100vh',
                    overflowY: 'auto',
                } : {
                    maxWidth: '90vw',
                    maxHeight: '95vh',
                    overflowY: 'auto',
                }}
            >
                <div className={`bg-white ${isMobile ? 'p-2 h-full' : 'p-5'} ${!isMobile && 'min-w-96 rounded-2xl'}`}>
                    <div className={`${isMobile ? 'gap-3' : 'justify-between'} flex mt-2 ml-2`}>
                        {
                            isMobile && <button className="btn btn-ghost btn-sm btn-circle" onClick={onClose}>
                                <i className="fa-solid fa-chevron-left"></i>
                            </button>
                        }

                        <div>
                            <h2 className="card-title">{title}</h2>
                            {
                                subTitle && <h6 className="text-sm">{subTitle}</h6>
                            }
                        </div>

                        {
                            !isMobile && <button className="btn btn-ghost btn-sm btn-circle" onClick={onClose}>
                                <i className="fa-solid fa-xmark"></i>
                            </button>
                        }
                    </div>

                    <div className="divider" style={{ margin: 5 }}></div>

                    <div className="w-full">{children}</div>
                </div>
            </div>
        </div>,
        document.getElementById('outside-root') as HTMLElement,
    );
};
