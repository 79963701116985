import React, { useState, useEffect } from 'react';
import 'daisyui/dist/full.css';
import { UserService } from '../Service';
import { useAppContext } from '../../../../contexts/AppContext';
import { useAuthContext } from '../../../../contexts/AuthContext';
import FileUploadUser from '../../../../components/files/FileUploadUser';

const service = new UserService();

export default function ConfiguracoesContaGeral() {
    const [loading, setLoading] = useState(false);
    const { user, changeUser } = useAuthContext();
    const { successSnackbar, errorSnackbar } = useAppContext();

    const [photoURL, setPhotoURL] = useState(user?.photoURL || '');
    const [displayName, setDisplayName] = useState(user?.displayName || '');

    const [initials, setInitials] = useState('');

    useEffect(() => {
        if (displayName) {
            const nameArray = displayName.trim().split(' ');
            const initials =
                nameArray.length > 1
                    ? nameArray[0][0].toUpperCase() + nameArray[nameArray.length - 1][0].toUpperCase()
                    : nameArray[0][0].toUpperCase();
            setInitials(initials);
        } else {
            setInitials('');
        }
    }, [displayName]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const update = { displayName, photoURL };

        try {
            setLoading(true);
            await service.updateProfile(update);

            changeUser(update);
            successSnackbar('Perfil editado com sucesso!');
        } catch (e) {
            errorSnackbar('Ocorreu um erro ao editar o perfil.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container mx-auto p-4">
            <form onSubmit={handleSubmit}>
                <div className="form-control mb-4">
                    <div className="grid">
                        <div className="mx-auto">
                            <FileUploadUser value={photoURL} setValue={setPhotoURL}>
                                {({ loading: loadingFile, fileData, error, handleChange, removeFile, inputRef }) => {
                                    const previewUrl = fileData?.url;

                                    return (
                                        <div className="grid">
                                            <div className="mx-auto mb-3">
                                                {!!previewUrl ? (
                                                    <div className="avatar">
                                                        <div className="w-20 rounded-full ring ring-orange-50 bg-neutral">
                                                            <img src={previewUrl} alt="Foto de perfil" />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="avatar placeholder">
                                                        <div className="w-20 rounded-full bg-neutral-focus text-neutral-content ring-offset-1 bg-neutral">
                                                            <span className="text-3xl">{initials}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div>
                                                <input
                                                    disabled={loading || loadingFile}
                                                    type="file"
                                                    id="profilePicture"
                                                    ref={inputRef}
                                                    accept="image/*"
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                    }}
                                                    className="file-input file-input-bordered file-input-xs w-36 md:w-44 md:file-input-sm"
                                                />
                                                {previewUrl && (
                                                    <button
                                                        disabled={loading || loadingFile}
                                                        type="button"
                                                        onClick={() => {
                                                            removeFile();
                                                        }}
                                                        className="btn btn-error ml-4 btn-sm"
                                                    >
                                                        <i className="fa-solid fa-trash"></i>
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    );
                                }}
                            </FileUploadUser>
                        </div>
                    </div>
                </div>
                <div className="form-control mb-4">
                    <label className="label" htmlFor="name">
                        <span className="label-text">Nome:</span>
                    </label>
                    <input
                        disabled={loading}
                        type="text"
                        id="name"
                        required
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                        className="input input-bordered w-full max-w-96"
                    />
                </div>

                <div className="divider" />

                <button className="btn shadow-md" type="submit" disabled={loading}>
                    Salvar
                </button>
            </form>
        </div>
    );
}
