import { useListAgendaContext } from './Context';
import { useState } from 'react';
import { AtendentesService } from '../../../atendentes/Service';
import { minutesToTime, timeToMinutes } from '../../Enum';
import { useAppContext } from '../../../../contexts/AppContext';
import { useAgendaContext } from '../ContextAgenda';

const atendentesService = new AtendentesService();

export const DateItemSettings = ({ date, onClose }: { date: string; onClose: () => void }) => {
    const [loading, setLoading] = useState(false);
    const { attendantId } = useListAgendaContext();
    const { settingsAgenda, loadSettings } = useAgendaContext();
    const { errorSnackbar, successSnackbar } = useAppContext();

    const [scheduleGap, setScheduleGap] = useState(settingsAgenda?.scheduleGap || 0);
    const [minAdvanceTime, setMinAdvanceTime] = useState(settingsAgenda?.minAdvanceTime || 0);
    const [horario, setHorario] = useState<{ start: number; end: number }[]>(settingsAgenda?.horario || []);
    const [closedSlots, setClosedSlots] = useState<{ start: number; end: number }[]>(settingsAgenda?.closedSlots || []);

    const submit = async () => {
        try {
            setLoading(true);

            const updatedDoc = {
                scheduleGap,
                minAdvanceTime,
                horario,
                closedSlots,
            };

            await atendentesService.updateAgendaDate({
                atendenteId: attendantId,
                date,
                update: {
                    agenda: updatedDoc,
                },
            });

            successSnackbar("Agenda salva com sucesso!")
            loadSettings()
        } catch (e) {
            console.error('Erro ao salvar:', e);
            errorSnackbar("Ocorreu um erro ao salvar a agenda.")
        } finally {
            setLoading(false);
        }
    };

    const handleAddSlot = () => {
        setHorario([...horario, { start: 0, end: 0 }]);
    };

    const handleAddClosedSlot = () => {
        setClosedSlots([...closedSlots, { start: 0, end: 0 }]);
    };

    const handleSlotChange = (slotIndex: number, field: 'start' | 'end', value: string, type: 'horario' | 'closedSlots') => {
        const targetArray = type === 'horario' ? [...horario] : [...closedSlots];
        targetArray[slotIndex][field] = timeToMinutes(value);
        type === 'horario' ? setHorario(targetArray) : setClosedSlots(targetArray);
    };

    const handleRemoveSlot = (slotIndex: number, type: 'horario' | 'closedSlots') => {
        const targetArray = type === 'horario' ? [...horario] : [...closedSlots];
        targetArray.splice(slotIndex, 1);
        type === 'horario' ? setHorario(targetArray) : setClosedSlots(targetArray);
    };

    return (
        <div>
            <div className="form-control mb-4">
                <label className="label" htmlFor="scheduleGap">
                    <span className="label-text">Intervalo mínimo entre agendamentos (em minutos)</span>
                </label>
                <input
                    type="number"
                    value={scheduleGap}
                    onChange={(e) => setScheduleGap(parseInt(e.target.value))}
                    id="scheduleGap"
                    className="input input-bordered"
                    min="10"
                />
            </div>

            <div className="form-control mb-4">
                <label className="label" htmlFor="minAdvanceTime">
                    <span className="label-text">Tempo mínimo de antecedência para agendamentos (em minutos)</span>
                </label>
                <input
                    type="number"
                    value={minAdvanceTime}
                    onChange={(e) => setMinAdvanceTime(parseInt(e.target.value))}
                    id="minAdvanceTime"
                    className="input input-bordered"
                    min="0"
                />
            </div>

            <div className="divider"></div>
            <h3 className="text-xl font-semibold mb-2">Horários de Atendimento</h3>
            {horario?.map((slot, slotIndex) => (
                <div key={slotIndex} className="flex items-center gap-4 mb-2">
                    <input
                        type="time"
                        value={minutesToTime(slot.start)}
                        onChange={(e) => handleSlotChange(slotIndex, 'start', e.target.value, 'horario')}
                        className="input input-bordered input-xs sm:input-md"
                    />
                    <input
                        type="time"
                        value={minutesToTime(slot.end)}
                        onChange={(e) => handleSlotChange(slotIndex, 'end', e.target.value, 'horario')}
                        className="input input-bordered input-xs sm:input-md"
                    />
                    <button
                        type="button"
                        onClick={() => handleRemoveSlot(slotIndex, 'horario')}
                        className="btn btn-error btn-xs"
                    >
                        Remover
                    </button>
                </div>
            ))}
            <button type="button" onClick={handleAddSlot} className="btn btn-sm">
                Adicionar Intervalo
            </button>

            <div className="divider"></div>
            <h3 className="text-xl font-semibold mb-2">Horários Fechados</h3>
            {closedSlots?.map((slot, slotIndex) => (
                <div key={slotIndex} className="flex items-center gap-4 mb-2">
                    <input
                        type="time"
                        value={minutesToTime(slot.start)}
                        onChange={(e) => handleSlotChange(slotIndex, 'start', e.target.value, 'closedSlots')}
                        className="input input-bordered input-xs sm:input-md"
                    />
                    <input
                        type="time"
                        value={minutesToTime(slot.end)}
                        onChange={(e) => handleSlotChange(slotIndex, 'end', e.target.value, 'closedSlots')}
                        className="input input-bordered input-xs sm:input-md"
                    />
                    <button
                        type="button"
                        onClick={() => handleRemoveSlot(slotIndex, 'closedSlots')}
                        className="btn btn-error btn-xs"
                    >
                        Remover
                    </button>
                </div>
            ))}
            <button type="button" onClick={handleAddClosedSlot} className="btn btn-sm">
                Adicionar Horário Fechado
            </button>

            <div className="divider"></div>
            <div className="flex justify-between">
                <button className="btn btn-ghost" onClick={onClose} disabled={loading}>
                    Cancelar
                </button>
                <button className="btn btn-primary" onClick={submit} disabled={loading}>
                    {loading ? 'Salvando...' : 'Salvar'}
                </button>
            </div>
        </div>
    );
};