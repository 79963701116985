import { useAgendaSettingsContext } from './Context';
import { useAuthContext } from '../../../../contexts/AuthContext';

export default function AgendaSettingsGeral() {
    const {
        daysOpen,
        setDaysOpen,
        submitGeral,
        loading,
        scheduleGap,
        setScheduleGap,
        setMinAdvanceTime,
        minAdvanceTime,
    } = useAgendaSettingsContext();

    return (
        <div>
            <div className="form-control mb-4">
                <label className="label" htmlFor="daysOpen">
                    <span className="label-text">Dias que a agenda ficará aberta</span>
                </label>
                <input
                    type="number"
                    value={daysOpen}
                    onChange={(e) => setDaysOpen(parseInt(e.target.value))}
                    id="daysOpen"
                    className="input input-bordered"
                    min="1"
                />
                <span className="text-sm text-gray-500 mb-2">
                    Defina o número de dias que a agenda estará disponível para agendamento antecipado pelos clientes.
                </span>
            </div>

            <div className="divider" />

            <div className="form-control mb-4">
                <label className="label" htmlFor="scheduleGap">
                    <span className="label-text">Intervalo mínimo entre agendamentos (em minutos)</span>
                </label>
                <input
                    type="number"
                    value={scheduleGap}
                    onChange={(e) => setScheduleGap(parseInt(e.target.value))}
                    id="scheduleGap"
                    className="input input-bordered"
                    min="10"
                />
                <span className="text-sm text-gray-500">
                    Defina o tempo mínimo entre um os horários. Exemplo: se for 10 minutos, horários disponíveis: 8:00, 8:10, 8:20...
                </span>
            </div>

            <div className="divider" />

            <div className="form-control mb-4">
                <label className="label" htmlFor="minAdvanceTime">
                    <span className="label-text">Tempo mínimo de antecedência para agendamentos (em minutos)</span>
                </label>
                <input
                    type="number"
                    value={minAdvanceTime}
                    onChange={(e) => setMinAdvanceTime(parseInt(e.target.value))}
                    id="minAdvanceTime"
                    className="input input-bordered"
                    min="0"
                />
                <span className="text-sm text-gray-500">
                    Defina quantos minutos antes um cliente pode agendar um horário. Exemplo: se for 30 minutos, e agora for 08:55, o primeiro horário disponível será 09:25.
                </span>
            </div>

            <div className="divider" />

            <button onClick={submitGeral} disabled={loading} className="btn shadow-md" type="submit">
                Salvar Alterações
            </button>
        </div>
    );
}
