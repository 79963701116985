import React from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { Loading } from './Loading';

interface DropdownAction {
    label: string;
    action: () => Promise<void>;
}

interface DynamicDropdownProps {
    label: string;
    isDisabled?: boolean;
    loading?: boolean;
    buttonClass: string;
    actions: DropdownAction[];
}

const Dropdown: React.FC<DynamicDropdownProps> = ({
    label,
    isDisabled = false,
    loading = false,
    actions,
    buttonClass,
}) => {
    const handleActionClick = async (action: () => Promise<void>) => {
        await action();
    };

    return (
        <Menu as="div" className="inline-block text-left">
            <div>
                <MenuButton
                    as={'button'}
                    disabled={isDisabled || loading}
                    className={`${loading && 'opacity-70'} rounded-md px-3 py-2 ring-1 ring-inset ring-gray-300 ${buttonClass}`}
                >
                    <div className={'flex gap-2'}>
                        {loading && <Loading size={'xs'} />} {label}
                    </div>
                </MenuButton>
            </div>

            {!!actions?.length && (
                <MenuItems
                    transition
                    className="teste  rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                    <div className="py-1">
                        {actions.map((action, index) => (
                            <MenuItem key={index}>
                                <a
                                    onClick={() => handleActionClick(action.action)}
                                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                >
                                    {action.label}
                                </a>
                            </MenuItem>
                        ))}
                    </div>
                </MenuItems>
            )}
        </Menu>
    );
};

export default Dropdown;
