import { useEffect, useState } from 'react';
import { Modal } from '../../../components/Modal';
import { EstabelecimentosService } from '../system-admin/Service';
import { UserService } from '../../configuracoes/usuario/Service';
import { Loading } from '../../../components/Loading';
import { useAppContext } from '../../../contexts/AppContext';
import { useEstabsContext } from '../../../contexts/EstabsContext';

const estabsService = new EstabelecimentosService();
const userService = new UserService();

interface AddEstabUserModalProps {
    onClose: () => void;
    estabId: string;
}

export const ToggleAddEstabUserModal = ({ onClose, estabId }: AddEstabUserModalProps) => {
    const { estabsUser } = useEstabsContext();
    const [estab, setEstab] = useState({} as any);

    const loadEstab = async (estabId: string) => {
        try {
            const { data } = await estabsService.getInfoById(estabId);

            setEstab(data);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        loadEstab(estabId);
    }, [estabId]);

    return (
        <Modal onClose={onClose}
               title={estab.name}
               subTitle={estabsUser.some((e) => e._id === estabId && e.type === 'client')
                   ? 'Deseja remover este estabelecimento da sua conta?'
                   : 'Deseja adicionar este estabelecimento a sua conta?'}>
            <ToggleAddEstabUser estabId={estabId} onClose={onClose} />
        </Modal>
    );
};

interface AddEstabUserProps {
    estabId: string;
    onClose?: () => void;
}

export const ToggleAddEstabUser = ({ estabId, onClose }: AddEstabUserProps) => {
    const [loading, setLoading] = useState(false);
    const { errorSnackbar, successSnackbar } = useAppContext();
    const { estabsUser, reloadEstab } = useEstabsContext();

    const addEstabUser = async () => {
        try {
            setLoading(true);

            await userService.addUserClientEstab(estabId);
            successSnackbar('Estabelecimento adicionado a sua conta com sucesso!');
            if (onClose) {
                onClose();
            }
        } catch (err) {
            console.log(err);
            errorSnackbar('Ocorreu um erro ao adicionar o estabelecimento a sua conta.');
        } finally {
            reloadEstab();
            setLoading(false);
        }
    };

    const removeEstabUser = async () => {
        try {
            setLoading(true);

            await userService.removeUserClientEstab(estabId);
            successSnackbar('Estabelecimento removido da sua conta com sucesso!');
            if (onClose) {
                onClose();
            }
        } catch (err) {
            console.log(err);
            errorSnackbar('Ocorreu um erro ao remover o estabelecimento da sua conta.');
        } finally {
            reloadEstab();
            setLoading(false);
        }
    };

    return (
        <div>
            <div className="flex justify-between">
                <button disabled={loading} className="btn btn-ghost" onClick={onClose}>
                    Cancelar
                </button>
                <button
                    disabled={loading}
                    onClick={estabsUser.some((e) => e._id === estabId && e.type === 'client') ? removeEstabUser : addEstabUser}
                    className="btn btn-primary"
                >
                    {loading ? <Loading /> : 'Confirmar'}
                </button>
            </div>
        </div>
    );
};

interface ToggleAddEstabUserBtnProps {
    estabId: string;
    small?: boolean;
}

export const ToggleAddEstabUserBtn = ({ estabId, small = false }: ToggleAddEstabUserBtnProps) => {
    const { estabsUser } = useEstabsContext();
    const [openAddEstab, setOpenAddEstab] = useState(false);

    return (
        <>
            <button
                onClick={() => setOpenAddEstab(true)}
                className={`btn ${small ? 'btn-xs' : 'btn-sm btn-primary'}`}
            >
                {estabsUser.some((e) => e._id === estabId && e.type === 'client') ? (
                    small ? (
                        <i className="fa-solid fa-trash"></i>
                    ) : (
                        'Remover'
                    )
                ) : small ? (
                    <i className="fa-solid fa-plus"></i>
                ) : (
                    'Adicionar'
                )}
            </button>
            {openAddEstab && <ToggleAddEstabUserModal estabId={estabId} onClose={() => setOpenAddEstab(false)} />}
        </>
    );
};
