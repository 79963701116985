import { ComponentType } from 'react';
import { useParamsContext } from '../../contexts/ParamsContext';
import { useNavigate } from 'react-router-dom';
interface HeaderProps {
    title: string;
    Actions: ComponentType | any;
    returnHome?: boolean;
}

export default function HeaderContent({ title, Actions, returnHome = false }: HeaderProps) {
    const navigate = useNavigate();

    const { action, clear } = useParamsContext();

    return (
        <div className="flex justify-between">
            <div className="flex items-center">
                {action && (
                    <button
                        className="btn btn-xs btn-circle btn-ghost mr-2"
                        onClick={() => {
                            clear();
                        }}
                    >
                        <i className="fa-solid fa-chevron-left"></i>
                    </button>
                )}

                {returnHome && (
                    <button
                        className="btn btn-circle btn-ghost btn-xs mr-2"
                        onClick={() => {
                            navigate('/');
                        }}
                    >
                        <i className="fa-solid fa-chevron-left"></i>
                    </button>
                )}

                <div className="text-lg sm:text-2xl font-bold">{title}</div>
            </div>

            <div className="h-9 min-w-1 flex items-center">{!!Actions && <Actions />}</div>
        </div>
    );
}
