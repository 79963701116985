import moment from 'moment';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAppContext } from '../../../../contexts/AppContext';
import { useParamsContext } from '../../../../contexts/ParamsContext';
import { useAgendaClienteContext } from '../Context';
import { AgendaClienteService } from '../Service';
import { AtendentesService } from '../../../atendentes/Service';
import { AxiosError } from 'axios';

const horarioService = new AgendaClienteService();
const serviceAtendentes = new AtendentesService();

interface FormHorarioClienteParams {
    date: moment.Moment;
    handlePreviousWeek: () => void;
    handleNextWeek: () => void;
    setDate: React.Dispatch<React.SetStateAction<moment.Moment>>;
    estab: any;
    setEstab: React.Dispatch<React.SetStateAction<any>>;
    servico: any;
    setServico: React.Dispatch<React.SetStateAction<any>>;
    data: any;
    setData: React.Dispatch<React.SetStateAction<any>>;
    atendente: any;
    setAtendente: React.Dispatch<React.SetStateAction<any>>;
    horario: number;
    setHorario: React.Dispatch<React.SetStateAction<number>>;
    submitForm: () => void;
    daysOpen: number;
    setDaysOpen: React.Dispatch<React.SetStateAction<number>>;
    openConfirm: boolean;
    loading: boolean;
    setOpenConfirm: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormHorarioClienteContext = createContext<FormHorarioClienteParams | undefined>(undefined);

export const useFormHorarioClienteContext = () => {
    const context = useContext(FormHorarioClienteContext);
    if (!context) {
        throw new Error('useFormHorarioClienteContext must be used within a FormHorarioClienteProvider');
    }
    return context;
};

export const FormHorarioClienteProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const { loadAgendamentos } = useAgendaClienteContext();
    const { setParams } = useParamsContext();
    const [openConfirm, setOpenConfirm] = useState(false);

    const [loading, setLoading] = useState(false);
    const { successSnackbar, errorSnackbar } = useAppContext();
    const [daysOpen, setDaysOpen] = useState(0);

    const [date, setDate] = useState(moment());

    const handlePreviousWeek = () => {
        setDate((prevDate) => {
            const newDate = moment(prevDate).subtract(7, 'days');
            const today = moment().startOf('day');
            return newDate.isBefore(today) ? today : newDate;
        });
    };

    const handleNextWeek = () => {
        setDate((prevDate) => {
            const newDate = moment(prevDate).add(7, 'days');
            const today = moment().startOf('day');
            const limit = today.clone().add(daysOpen, 'days');
            return newDate.isAfter(limit) ? limit : newDate;
        });
    };

    const [estab, setEstab] = useState({} as any);
    const [servico, setServico] = useState({} as any);
    const [data, setData] = useState({} as any);
    const [atendente, setAtendente] = useState({} as any);
    const [horario, setHorario] = useState(0);

    const loadAgendaConfig = async () => {
        try {
            setLoading(true);

            const { data } = await serviceAtendentes.getAgendaDate(atendente.uid, date.toString(), estab._id);

            setDaysOpen(data.daysOpen -1 || 0);
        } catch (e) {

        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setData({});
        setHorario(0);

        loadAgendaConfig()
    }, [atendente]);

    const submitForm = async () => {
        const agendamentoData = {
            startMinutes: horario,
            estabId: estab._id,
            startAt: moment(data).startOf('days').add(horario, 'minutes').toDate(),
            servicoId: servico._id,
            attendantId: atendente.uid,
        };

        try {
            setLoading(true);
            const { data } = await horarioService.createAgendamento(agendamentoData);
            successSnackbar('Agendamento criado com sucesso!');
            loadAgendamentos();

            setParams({ action: 'new', id: data?._id });
        } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.message === 'conflictHours') {
                errorSnackbar('O horário selecionado já foi agendado. Por favor, escolha outro horário.');
            } else {
                errorSnackbar('Houve um erro inesperado ao tentar criar o agendamento. Tente novamente mais tarde.');
            }
        } finally {
            setLoading(false);
        }

        setOpenConfirm(false);
    };

    return (
        <FormHorarioClienteContext.Provider
            value={{
                date,
                handlePreviousWeek,
                handleNextWeek,
                setDate,
                estab,
                daysOpen,
                setDaysOpen,
                setEstab,
                servico,
                setServico,
                data,
                setData,
                atendente,
                setAtendente,
                horario,
                setHorario,
                submitForm,
                openConfirm,
                setOpenConfirm,
                loading,
            }}
        >
            {children}
        </FormHorarioClienteContext.Provider>
    );
};
