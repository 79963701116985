import moment from 'moment';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useParamsContext } from '../../../contexts/ParamsContext';
import { AusenciasProvider } from '../../ausencias/Context';
import { Agendamento } from '../Enum';
import { HorariosAtendenteService } from '../Service';
import { useListAgendaContext } from './list/Context';
import { WebSocketManager } from '../../../WebSocketConfig';
import { useEstabsContext } from '../../../contexts/EstabsContext';
import { AtendentesService } from '../../atendentes/Service';
import { Horario } from '../interfaces';

const atendentesService = new AtendentesService();
const horariosService = new HorariosAtendenteService();

interface AgendaParams {
    loading: boolean;
    date: moment.Moment;
    schedules: Agendamento[] | undefined;
    selectedSchedule: Agendamento | undefined;
    startDay: number;
    endDay: number;
    selectedRange: { start: number; end: number } | undefined;
    setSelectedRange: React.Dispatch<React.SetStateAction<{ start: number; end: number } | undefined>>;
    settingsAgenda: any
    loadSettings: any
}

const AgendaContext = createContext<AgendaParams | undefined>(undefined);

export const useAgendaContext = () => {
    const context = useContext(AgendaContext);
    if (!context) {
        throw new Error('useAgendaContext must be used within an AgendaProvider');
    }
    return context;
};

export const AgendaProvider: React.FC<{ date: string; children: React.ReactNode }> = ({ date, children }) => {
    const { action, id } = useParamsContext();
    const { estabSession } = useEstabsContext();
    const [loading, setLoading] = useState(true);
    const { attendantId } = useListAgendaContext();
    const [schedules, setSchedules] = useState<Agendamento[] | undefined>(undefined);
    const [selectedSchedule, setSelectedSchedule] = useState<Agendamento | undefined>(undefined);
    const [startDay, setStartDay] = useState<number>(0);
    const [endDay, setEndDay] = useState<number>(24);
    const [selectedRange, setSelectedRange] = useState<{ start: number; end: number } | undefined>();
    const [settingsAgenda, setSettingsAgenda] = useState<any>(undefined);

    const fetchSchedules = async (date: moment.Moment) => {
        try {
            const { data } = await horariosService.getHorariosCards(date.toDate(), attendantId);
            setSchedules(data);
        } catch (error) {
            console.log('Error fetching schedules:', error);
        } finally {
            setLoading(false);
        }
    };

    const loadSettings = async () => {
        try {
            setLoading(true);

            const { data } = await atendentesService.getAgendaDate(attendantId, date);

            setSettingsAgenda(data);
            if (data.horario && Array.isArray(data.horario)) {
                const horarios: Horario[] = data.horario;

                if(!horarios?.length) return

                const startValues = horarios.map((h) => h.start);
                const endValues = horarios.map((h) => h.end);

                setStartDay((Math.min(...startValues) / 60) - 0.151);
                setEndDay((Math.max(...endValues) / 60) + 0.151);
            }
        } catch (e) {
            console.error('Erro ao carregar configurações:', e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSchedules(moment(date));
        loadSettings();
    }, [date, attendantId]);

    useEffect(() => {
        const webSocketManager = new WebSocketManager();

        setTimeout(() => {
            if (webSocketManager.socket) {
                webSocketManager.onChannel(`horarios:atendentes:${estabSession._id}`, () => fetchSchedules(moment(date)));
            }
        }, 1000);

        return () => {
            webSocketManager.disconnect();
        };
    }, [attendantId, estabSession]);

    useEffect(() => {
        if (action === 'view' && !!id) {
            setSelectedSchedule(schedules?.find((e) => e._id === id));
        } else {
            setSelectedSchedule(undefined);
        }
    }, [action, id, schedules]);

    return (
        <AgendaContext.Provider
            value={{
                loading,
                schedules,
                selectedSchedule,
                date: moment(date),
                startDay,
                endDay,
                selectedRange,
                setSelectedRange,
                settingsAgenda,
                loadSettings
            }}
        >
            <AusenciasProvider>{children}</AusenciasProvider>
        </AgendaContext.Provider>
    );
};
