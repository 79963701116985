import { useDashboardContext } from './Context';
import { ChatsStatus } from './Statuses';
import { DashboardHeader } from './Header';
import { ChatsConclusoesMes } from './ConclusoesMes';
import { ChatsFaturamentoMes } from './FaturamentoMes';
import { ChartVisaoGeral } from './VisaoGeral';
import moment from 'moment';
import SelectAtendentes from '../../components/inputs/SelectAtendentes';
import { useAuthContext } from '../../contexts/AuthContext';

export const Dashboards = () => {
    const { user } = useAuthContext();
    const { competence, setCompetence, atendenteId, setAtendenteId } = useDashboardContext();

    return (
        <div className="pb-4">
            <div className="mb-5">
                {
                    (user?.admin || user?.root) &&
                    <SelectAtendentes value={atendenteId} setValue={setAtendenteId}
                                      name={'atendente'} placeholder={'Todos'}
                                      multiple={false} />
                }

            </div>

            <div className="w-full">
                <div className="card-body" style={{ padding: '0px' }}>
                    <div className="mx-auto items-center grid md:flex gap-2">
                        <input
                            type="month"
                            value={competence}
                            onChange={(e) => setCompetence(e.target.value)}
                            className="input float-start mb-5 ml-2"
                            style={{ margin: 0 }}
                        />
                        <div className="flex gap-2 justify-center">
                            <button
                                className="btn btn-sm btn-circle"
                                onClick={() => {
                                    setCompetence(
                                        moment(competence).subtract(1, 'months').format('YYYY-MM'),
                                    );
                                }}
                            >
                                <i className="fa-solid fa-chevron-left"></i>
                            </button>

                            <button
                                className="btn btn-sm btn-circle"
                                onClick={() => {
                                    setCompetence(moment(competence).add(1, 'months').format('YYYY-MM'));
                                }}
                            >
                                <i className="fa-solid fa-chevron-right"></i>
                            </button>
                        </div>
                    </div>

                    <DashboardHeader />

                    <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-6 mt-8 md:mt-0">
                        <div className="w-full">
                            <ChatsConclusoesMes />
                        </div>
                        <div className="w-full">
                            <ChatsFaturamentoMes />
                        </div>
                    </div>
                </div>
            </div>

            <div className="divider"></div>

            <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-6">
                <div className="w-full">
                    <ChartVisaoGeral />
                </div>
                <div className="w-full">
                    <ChatsStatus />
                </div>
            </div>
        </div>
    );
};
