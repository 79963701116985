import React, { useEffect, useState } from 'react';
import { useParamsContext } from '../../../contexts/ParamsContext';
import { EstabelecimentosService } from './Service';
import { useAppContext } from '../../../contexts/AppContext';
import { useEstabsContext } from './Context';

const service = new EstabelecimentosService();

export default function EstabelecimentosForm() {
    const { clear, id } = useParamsContext();
    const { data, reloadData } = useEstabsContext();
    const { successSnackbar, errorSnackbar } = useAppContext();
    const [disabledButton, setDisabledButton] = useState(false);

    const [name, setName] = useState('');
    const [urlApp, setUrlApp] = useState('');
    const [whatsappToken, setWhatsappToken] = useState('');
    const [whatsappPhoneNumberId, setWhatsappPhoneNumberId] = useState('');

    useEffect(() => {
        const doc: any = data?.find((e) => e._id === id);

        if (!!doc) {
            setName(doc.name);
            setUrlApp(doc.urlApp);
            setWhatsappToken(doc.whatsappToken || '');
            setWhatsappPhoneNumberId(doc.whatsappPhoneNumberId || '');
        }
    }, [id, data]);

    const submit = async (event: React.FormEvent<HTMLFormElement>) => {
        setDisabledButton(true);
        event.preventDefault();

        try {
            const estabelecimento: any = { name };

            estabelecimento.urlApp = urlApp || '';
            estabelecimento.whatsappToken = whatsappToken || '';
            estabelecimento.whatsappPhoneNumberId = whatsappPhoneNumberId || '';

            if (!!id) {
                await service.update(id, estabelecimento);
                successSnackbar('Estabelecimento editado com sucesso!');
            } else {
                await service.create(estabelecimento);
                successSnackbar('Estabelecimento criado com sucesso!');
            }

            reloadData();
        } catch (e) {
            console.log(e);
            errorSnackbar('Ocorreu um erro ao criar o estabelecimento.');
        } finally {
            setDisabledButton(false);
            clear();
        }
    };

    return (
        <div>
            <form onSubmit={submit}>
                <label className="form-control">
                    <div className="label">
                        <span className="label-text">Nome</span>
                    </div>
                    <input
                        type="text"
                        className="input input-bordered"
                        placeholder="Nome do estabelecimento"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </label>
                <label className="form-control">
                    <div className="label">
                        <span className="label-text">Token WhatsApp</span>
                    </div>
                    <input
                        type="text"
                        className="input input-bordered"
                        placeholder="Token do WhatsApp"
                        value={whatsappToken}
                        onChange={(e) => setWhatsappToken(e.target.value)}
                    />
                </label>
                <label className="form-control">
                    <div className="label">
                        <span className="label-text">Id Phone Number WhatsApp</span>
                    </div>
                    <input
                        type="text"
                        className="input input-bordered"
                        placeholder="Id Número de telefone do WhatsApp"
                        value={whatsappPhoneNumberId}
                        onChange={(e) => setWhatsappPhoneNumberId(e.target.value)}
                    />
                </label>

                <label className="form-control">
                    <div className="label">
                        <span className="label-text">URL App</span>
                    </div>
                    <input
                        type="text"
                        className="input input-bordered"
                        placeholder="URL App"
                        value={urlApp}
                        onChange={(e) => setUrlApp(e.target.value)}
                    />
                </label>

                <div className="divider"></div>

                <button className="btn shadow-md" disabled={disabledButton} type="submit">
                    {!!id ? 'Editar' : 'Adicionar'}
                </button>
            </form>
        </div>
    );
}
