import { io, Socket } from 'socket.io-client';
import { baseURL } from './AxiosConfig';
import { getAuth } from 'firebase/auth';

export class WebSocketManager {
    socket: Socket | null = null;

    constructor() {
        this.initializeSocket();
    }

    private async initializeSocket() {
        try {
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (currentUser) {
                const token = await currentUser.getIdToken();
                this.socket = io(baseURL, {
                    path: '/socket.io',
                    transports: ['websocket'],
                    auth: {
                        token: token,
                    },
                    secure: true
                });
            }
        } catch (error) {
            console.error('Erro ao inicializar o socket:', error);
        }
    }

    onChannel(channel: string, callback: (message?: any) => void) {
        if (this.socket) {
            this.socket.on(channel, callback);
        } else {
            console.log('error websocket');
        }
    }

    sendMessage(channel: string, message: any) {
        if (this.socket) {
            this.socket.emit(channel, message);
        }
    }

    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }
}

