import { api } from '../../AxiosConfig';

export class DashboardService {
    getInfoHeader(competence: string, atendenteId?: string) {
        return api.get(`dashboard/getInfoHeader/${competence}/${atendenteId}`);
    }

    getConclusoesCompetence(competence: string, atendenteId?: string) {
        return api.get(`dashboard/getConclusoesCompetence/${competence}/${atendenteId}`);
    }

    getFaturamentoMes(competence: string, atendenteId?: string) {
        return api.get(`dashboard/getFaturamentoMes/${competence}/${atendenteId}`);
    }

    getStatusAtual(atendenteId?: string) {
        return api.get(`dashboard/getStatusAtual/${atendenteId}`);
    }

    getVisaoGeral(currentDate: string, atendenteId?: string) {
        return api.get(`dashboard/getVisaoGeral/${currentDate}/${atendenteId}`);
    }
}
