import React, { useEffect, useState } from 'react';
import { Routes, useNavigate } from 'react-router-dom';
import Header from './header/Header';
import Menu from './menu/Menu';
import { menus, userRoutes } from '../routes';
import { useAppContext } from '../contexts/AppContext';
import { useAuthContext } from '../contexts/AuthContext';
import MenuDrawer from './menu/MenuDrawer';
import { Modal } from '../components/Modal';
import ConfiguracoesContaPhoneNumber from '../modules/configuracoes/usuario/conta/PhoneNumber';

export default function DashboardPage() {
    const { user } = useAuthContext();
    const [routes, setRoutes] = useState<any>([]);
    const [openFormPhoneNumber, setOpenFormPhoneNumer] = useState(false);
    const { shortMenu, showDrawerMenu, toggleShowDrawerMenu } = useAppContext();

    useEffect(() => {
        if (user) {
            setRoutes(userRoutes(user));
        }
    }, [user]);

    useEffect(() => {
        const modalShownTime = localStorage.getItem('modalShownTime');

        if (!openFormPhoneNumber && !user?.phoneNumber && !!user?.client) {
            if (modalShownTime) {
                const timeElapsed = Date.now() - parseInt(modalShownTime, 10);
                if (timeElapsed > 5 * 60 * 1000) {
                    setOpenFormPhoneNumer(true);
                    localStorage.setItem('modalShownTime', Date.now().toString());
                }
            } else {
                setOpenFormPhoneNumer(true);
                localStorage.setItem('modalShownTime', Date.now().toString());
            }
        }
    }, [openFormPhoneNumber, user?.phoneNumber]);

    return (
        <div className="mx-auto w-12/12 2xl:w-10/12">
            {openFormPhoneNumber && (
                <Modal onClose={() => setOpenFormPhoneNumer(false)} title="Ative sua conta WhatsApp!" subTitle="Informe seu número de telefone para receber notificações, agendar horários e acessar
                                outras facilidades diretamente pelo WhatsApp.">
                    <img
                        src="whatsapp.png"
                        alt="WhatsApp"
                        className="mb-4 w-full h-48 object-cover rounded-lg"
                    />
                    <ConfiguracoesContaPhoneNumber />
                </Modal>
            )}

            <div className="mb-2 pb-3 fixed z-50 w-full 2xl:w-10/12">
                <Header />
            </div>

            <div className="flex">
                {
                    menus(user)?.length > 1 && <div className="fixed mt-20 z-40 hidden md:block">
                        <Menu />
                    </div>
                }

                <div className="fixed mt-20 z-40 block md:hidden">
                    <div className="drawer">
                        <input id="my-drawer" type="checkbox" className="drawer-toggle" checked={showDrawerMenu} />
                        <div className="drawer-side">
                            <label
                                htmlFor="my-drawer"
                                aria-label="close sidebar"
                                className="drawer-overlay"
                                onClick={toggleShowDrawerMenu}
                            ></label>
                            <MenuDrawer />
                        </div>
                    </div>
                </div>

                <div
                    className={`pt-1 w-full mt-20 mr-2 ml-2 ${menus(user)?.length > 1 && `${shortMenu ? 'md:ml-24' : 'md:ml-52'}`}`}>
                    {!!routes?.length && <Routes>{routes}</Routes>}
                </div>
            </div>
        </div>
    );
}
