import axios from 'axios';
import { brandingEstabId } from './branding';
import { getAuth } from 'firebase/auth';

export const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
export const urlFile = `${baseURL}/files`;

const api = axios.create({
    baseURL: baseURL,
});

api.interceptors.request.use(
    async (config) => {
        const auth = getAuth();
        const currentUser = auth.currentUser;

        if (!!currentUser) {
            try {
                const token = await currentUser.getIdToken();

                if (token) {
                    // Salva no localStorage
                    localStorage.setItem("authToken", token);
                    config.headers["Authorization"] = `Bearer ${token}`;
                }
            } catch (error) {
                console.error("Erro ao obter token, tentando recuperar do localStorage", error);

                // Recupera o token do localStorage em caso de erro
                const cachedToken = localStorage.getItem("authToken");
                if (cachedToken) {
                    config.headers["Authorization"] = `Bearer ${cachedToken}`;
                }
            }
        }

        if (!!brandingEstabId) {
            config.headers['Brandingestabid'] = brandingEstabId;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export { api };
