import moment from 'moment';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAppContext } from '../../../../contexts/AppContext';
import { baseURL } from '../../../../AxiosConfig';
import { UserService } from '../../../configuracoes/usuario/Service';
import { AtendentesService } from '../../../atendentes/Service';
import { HorariosAtendenteService } from '../../Service';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

const userService = new UserService();
const serviceAtendentes = new AtendentesService();
const horariosAtendenteService = new HorariosAtendenteService()

interface ClientData {
    value: any,
    label: any
}

interface FormHorarioClienteParams {
    date: moment.Moment;
    handlePreviousWeek: () => void;
    handleNextWeek: () => void;
    setDate: React.Dispatch<React.SetStateAction<moment.Moment>>;
    servico: any;
    setServico: React.Dispatch<React.SetStateAction<any>>;
    data: any;
    setData: React.Dispatch<React.SetStateAction<any>>;
    atendente: any;
    setAtendente: React.Dispatch<React.SetStateAction<any>>;
    horario: number;
    setHorario: React.Dispatch<React.SetStateAction<number>>;
    daysOpen: number;
    setDaysOpen: React.Dispatch<React.SetStateAction<number>>;
    clientId: string;
    setClientId: React.Dispatch<React.SetStateAction<string>>;
    clients: ClientData[] | [];
    loading: boolean;
    openConfirm: boolean;
    setOpenConfirm: React.Dispatch<React.SetStateAction<boolean>>;
    submitForm: () => void;
}

const FormHorarioClienteContext = createContext<FormHorarioClienteParams | undefined>(undefined);

export const useFormHorarioClienteContext = () => {
    const context = useContext(FormHorarioClienteContext);
    if (!context) {
        throw new Error('useFormHorarioClienteContext must be used within a FormHorarioClienteProvider');
    }
    return context;
};

export const FormHorarioClienteProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const navigate = useNavigate();

    const [openConfirm, setOpenConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const { successSnackbar, errorSnackbar } = useAppContext();
    const [daysOpen, setDaysOpen] = useState(0);

    const [date, setDate] = useState(moment());

    const [horario, setHorario] = useState(0);
    const [data, setData] = useState({} as any);
    const [servico, setServico] = useState({} as any);
    const [clientId, setClientId] = useState<string>('');
    const [atendente, setAtendente] = useState({} as any);
    const [clients, setClients] = useState<ClientData[] | []>([]);

    const handlePreviousWeek = () => {
        setDate((prevDate) => {
            const newDate = moment(prevDate).subtract(7, 'days');
            const today = moment().startOf('day');
            return newDate.isBefore(today) ? today : newDate;
        });
    };

    const handleNextWeek = () => {
        setDate((prevDate) => {
            const newDate = moment(prevDate).add(7, 'days');
            const today = moment().startOf('day');
            const limit = today.clone().add(daysOpen, 'days');
            return newDate.isAfter(limit) ? limit : newDate;
        });
    };

    const fetchClient = async () => {
        try {
            const { data } = await userService.getClients();
            const clientsOptions = data.map((client: any) => ({
                label: (
                    <div className="flex items-center gap-2">
                        <img src={`${baseURL}/users/profileImage/${client?.uid}`} alt="User Avatar"
                             className="w-6 h-6 rounded-full object-cover" />
                        <span>{client.displayName}</span>
                    </div>
                ),
                value: client.uid,
            }));
            setClients(clientsOptions);
        } catch (error) {
            console.error('Erro ao buscar clientes:', error);
        } finally {
            setLoading(false);
        }
    };

    const loadAgendaConfig = async () => {
        try {
            setLoading(true);

            const { data } = await serviceAtendentes.getAgendaDate(atendente.uid, date.toString());

            setDaysOpen(data.daysOpen - 1 || 0);
        } catch (e) {

        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setData({});
        setHorario(0);
        setClientId('');

        fetchClient();
        loadAgendaConfig();
    }, [atendente]);

    const submitForm = async () => {
        const agendamentoData = {
            startMinutes: horario,
            startAt: moment(data).startOf('days').add(horario, 'minutes').toDate(),
            servicoId: servico._id,
            attendantId: atendente.uid,
            clientId,
        };

        try {
            setLoading(true);

            await horariosAtendenteService.createAgendamento(agendamentoData)
            successSnackbar('Agendamento criado com sucesso!');
            navigate('/')
        } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.message === 'conflictHours') {
                errorSnackbar('O horário selecionado já foi agendado. Por favor, escolha outro horário.');
            } else {
                errorSnackbar('Houve um erro inesperado ao tentar criar o agendamento. Tente novamente mais tarde.');
            }
        } finally {
            setLoading(false);
        }

        setOpenConfirm(false);
    };

    return (
        <FormHorarioClienteContext.Provider
            value={{
                date,
                handlePreviousWeek,
                handleNextWeek,
                setDate,
                servico,
                setServico,
                data,
                setData,
                atendente,
                setAtendente,
                horario,
                daysOpen, setDaysOpen,
                setHorario,
                clientId,
                setClientId,
                clients,
                loading,
                openConfirm,
                setOpenConfirm,
                submitForm,
            }}
        >
            {children}
        </FormHorarioClienteContext.Provider>
    );
};
