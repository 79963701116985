import React from 'react';
import Select, { MultiValue, SingleValue, ActionMeta } from 'react-select';

interface Option {
    value: string;
    label: string;
}

interface MultiSelectProps {
    options: Option[];
    value: string | string[];
    onChange: (selected: string | string[]) => void;
    placeholder: string;
    label: string;
    loading?: boolean;
    disabled?: boolean;
    name?: string;
    multiple?: boolean;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
                                                     options,
                                                     value,
                                                     onChange,
                                                     placeholder,
                                                     label,
                                                     name,
                                                     loading = false,
                                                     disabled = false,
                                                     multiple = true,
                                                 }) => {
    const handleChange = (
        newValue: MultiValue<Option> | SingleValue<Option>,
        actionMeta: ActionMeta<Option>
    ) => {
        if (multiple) {
            const selectedValues = (newValue as MultiValue<Option>).map((option) => option.value);
            onChange(selectedValues);
        } else {
            onChange((newValue as SingleValue<Option>)?.value || '');
        }
    };

    const selectedOptions = multiple
        ? options.filter((option) => (value as string[]).includes(option.value))
        : options.find((option) => option.value === value);

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            borderColor: '#D1D5DB',
            borderRadius: '0.375rem',
            padding: '0.5rem',
            '&:hover': {
                borderColor: '#D1D5DB',
            },
            boxShadow: 'none',
        }),
        multiValue: (provided: any) => ({
            ...provided,
            backgroundColor: '#E5E7EB',
            borderRadius: '0.375rem',
        }),
        multiValueLabel: (provided: any) => ({
            ...provided,
            color: '#374151',
        }),
        multiValueRemove: (provided: any) => ({
            ...provided,
            color: '#6B7280',
            '&:hover': {
                backgroundColor: '#F3F4F6',
                color: '#000',
            },
        }),
        placeholder: (provided: any) => ({
            ...provided,
            color: '#9CA3AF',
        }),
    };

    return (
        <div className="form-control">
            <div className="label">
                <span className="label-text">{label}</span>
            </div>
            <Select
                isMulti={multiple}
                options={options}
                value={selectedOptions}
                onChange={handleChange}
                placeholder={placeholder}
                isDisabled={disabled}
                isClearable={true}
                styles={customStyles}
                isLoading={loading}
                className="basic-multi-select z-30"
                classNamePrefix="select"
                name={name}
                noOptionsMessage={() => 'Nenhuma opção disponível'}
            />
        </div>
    );
};

export default MultiSelect;
