import moment from 'moment/moment';
import { useAgendaContext } from '../ContextAgenda';
import { AgendaEstabelecimentoDate } from './List';
import { Modal } from '../../../../components/Modal';
import React, { useState } from 'react';
import { Loading } from '../../../../components/Loading';
import { AtendentesService } from '../../../atendentes/Service';
import { useListAgendaContext } from './Context';
import { useAppContext } from '../../../../contexts/AppContext';

const atendentesService = new AtendentesService();

interface ExtraActionsAgendaProps {
    agenda: AgendaEstabelecimentoDate;
}

export const ExtraActionsAgenda = ({ agenda }: ExtraActionsAgendaProps) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { attendantId } = useListAgendaContext();
    const { errorSnackbar, successSnackbar } = useAppContext();
    const { selectedRange, setSelectedRange, loadSettings } = useAgendaContext();

    const formatTime = (minutes: any) => moment().startOf('day').add(minutes, 'minutes').format('HH:mm');

    const submit = async () => {
        try {
            setLoading(true);

            if (!!selectedRange) {
                await atendentesService.addClosedSlots({
                    date: agenda.date,
                    atendenteId: attendantId,
                    closedSlots: selectedRange,
                });
            }

            setSelectedRange(undefined);
            loadSettings();
            successSnackbar('Agenda salva com sucesso!');
        } catch (e) {
            console.error('Erro ao salvar:', e);
            errorSnackbar('Ocorreu um erro ao salvar a agenda.');
        } finally {
            setLoading(false);
        }
    };

    return <div className="p-1">
        <div
            className="shadow-lg backdrop-blur-sm p-5 bg-white bg-opacity-90 rounded-r-3xl rounded-bl-3xl rounded-tl-0 text-center">
            <div className="text-xs md:text-lg text-primary">
                <i className="fa-solid fa-clock"></i> {formatTime(selectedRange?.start)} - {formatTime(selectedRange?.end)}
                <div className="text-xs text-primary">
                    {moment(agenda.date).format('dddd')} {moment(agenda.date).format('DD [de] MMMM')}
                </div>
            </div>

            <div className="divider" style={{ margin: 0 }}></div>

            <button
                className="btn btn-sm btn-error"
                onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                }}
            >
                <i className="fa-solid fa-lock"></i>
            </button>

            <button
                className="btn btn-xs btn-circle absolute top-0 right-0"
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setSelectedRange(undefined);
                }}
            >
                <i className="fa-solid fa-xmark"></i>
            </button>
        </div>

        {
            open && <Modal onClose={() => setOpen(false)}
                           title="Fechar Horarios"
                           subTitle={<div>
                               <div>
                                   {`Deseja realmente fechar o horário ${formatTime(selectedRange?.start)} - ${formatTime(selectedRange?.end)}`}
                               </div>
                               <div>
                                   {moment(agenda.date).format('dddd')} {moment(agenda.date).format('DD [de] MMMM')}
                               </div>
                           </div>}>
                <div className="flex justify-between">
                    <button className="btn btn-ghost" onClick={() => setOpen(false)} disabled={loading}>
                        Cancelar
                    </button>
                    <button className="btn btn-primary" onClick={submit} disabled={loading}>
                        {loading ? <Loading /> : 'Confirmar'}
                    </button>
                </div>
            </Modal>
        }
    </div>;
};