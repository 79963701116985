import React from 'react';
import { Menu } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useLandingPageContext } from '../Context';

export const Navbar = () => {
    const navigate = useNavigate();
    const { estab } = useLandingPageContext();

    return (
        <div className="navbar shadow-2xl fixed z-50 bg-black/85 backdrop-blur-md">
            <div className="navbar-start">
                <div className="dropdown">
                    <button tabIndex={0} className="btn btn-ghost lg:hidden">
                        <Menu className="text-white" />
                    </button>
                    <ul
                        tabIndex={0}
                        className="menu menu-sm dropdown-content mt-3 z-10 p-2 shadow bg-gray-700 text-white rounded-box w-52"
                    >
                        {!!estab?.about && (
                            <li>
                                <a href="#about" className="hover:text-white/40">
                                    Sobre
                                </a>
                            </li>
                        )}
                        {!!estab?.services?.length && (
                            <li>
                                <a href="#services" className="hover:text-white/40">
                                    Serviços
                                </a>
                            </li>
                        )}

                        {!!estab?.images?.length && (
                            <li>
                                <a href="#gallery" className="hover:text-white/40">
                                    Galeria
                                </a>
                            </li>
                        )}

                        {!!estab?.socialLinks?.length && (
                            <li>
                                <a href="#redeSocial" className="hover:text-white/40">
                                    Redes Sociais
                                </a>
                            </li>
                        )}

                        <li>
                            <a href="#contact" className="hover:text-white/40">
                                Contato
                            </a>
                        </li>

                        {!!estab?.contact?.addressUrl && (
                            <li>
                                <a
                                    href={estab.contact.addressUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="hover:text-white/40"
                                >
                                    Localização
                                </a>
                            </li>
                        )}

                        {!!estab?.contact?.urlWhatsapp && (
                            <li>
                                <a
                                    href={estab.contact.urlWhatsapp}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="hover:text-white/40"
                                >
                                    WhatsApp
                                </a>
                            </li>
                        )}
                    </ul>
                </div>

                <a className="btn btn-ghost text-xl font-bold" style={{ color: 'white' }}>
                    {estab?.title}
                </a>
            </div>

            <div className="navbar-center hidden lg:flex">
                <ul className="menu menu-horizontal px-1">
                    {!!estab?.about && (
                        <li>
                            <a href="#about" className="text-white hover:text-white/40">
                                Sobre
                            </a>
                        </li>
                    )}
                    {!!estab?.services?.length && (
                        <li>
                            <a href="#services" className="text-white hover:text-white/40">
                                Serviços
                            </a>
                        </li>
                    )}
                    {!!estab?.images?.length && (
                        <li>
                            <a href="#gallery" className="text-white hover:text-white/40">
                                Galeria
                            </a>
                        </li>
                    )}

                    {!!estab?.socialLinks?.length && (
                        <li>
                            <a href="#redeSocial" className="text-white hover:text-white/40">
                                Redes Sociais
                            </a>
                        </li>
                    )}

                    <li>
                        <a href="#contact" className="text-white hover:text-white/40">
                            Contato
                        </a>
                    </li>

                    {!!estab?.contact?.addressUrl && (
                        <li>
                            <a
                                href={estab.contact.addressUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-white hover:text-white/40"
                            >
                                Localização
                            </a>
                        </li>
                    )}

                    {!!estab?.contact?.urlWhatsapp && (
                        <li>
                            <a
                                href={estab.contact.urlWhatsapp}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-white hover:text-white/40"
                            >
                                WhatsApp
                            </a>
                        </li>
                    )}
                </ul>
            </div>

            <div className="navbar-end">
                <a
                    className="btn btn-sm border-t-neutral-950 flex items-center gap-2 text-white"
                    href="#login"
                    onClick={() => navigate(`/login?estabId=${estab?._id}`)}
                >
                    Login
                </a>
            </div>
        </div>
    );
};
