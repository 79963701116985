import { api } from '../../../AxiosConfig';

interface UserProfile {
    displayName: string;
    photoURL: string;
    phoneNumber?: string;
}

class UserService {
    create(data: { email: string; name: string; estabId?: string }) {
        return api.post('users/clients/create', data);
    }

    switchEstab(estabId: string) {
        return api.post('users/switch-estab', { estabId });
    }

    getSettings() {
        return api.get('users/settings');
    }

    updateSettings(update: any) {
        return api.post('users/settings/update', { update });
    }

    activeAccount(uid: string, code: string, password: string) {
        return api.post('users/activeAccount', { uid, code, password });
    }

    updateProfile(update: UserProfile) {
        return api.post('users/updateProfile', update);
    }

    removePhoneNumber() {
        return api.post('users/removePhoneNumber');
    }

    requestChangeEmail(email: string) {
        return api.post('users/requestChangeEmail', { email });
    }

    saveTokenPushNotification(token: string) {
        return api.post('users/saveTokenPushNotification', { token });
    }

    requestRecoverPassword(email: string) {
        return api.post('users/requestRecoverPassword', { email });
    }

    recoverPassword({
                        uid, code, password,
                    }: {
        uid: string; code: string; password: string;
    }) {
        return api.post('users/recoverPassword', {
            uid, code, password,
        });
    }

    changeEmail(code: string) {
        return api.post('users/changeEmail', { code });
    }

    requestChangePhoneNumber(phoneNumber: string) {
        return api.post('users/requestChangePhoneNumber', { phoneNumber });
    }

    changePhoneNumber(code: string, uid: string) {
        return api.post('users/changePhoneNumber', { code, uid });
    }

    addUserClientEstab(estabId: string) {
        return api.post('users/addUserClientEstab', { estabId });
    }

    removeUserClientEstab(estabId: string) {
        return api.post('users/removeUserClientEstab', { estabId });
    }

    getEstabsUser() {
        return api.get('/users/getEstabsUser');
    }

    getClients() {
        return api.get('/users/clients');
    }

    removeClient(uid: string) {
        return api.post('/users/clients/remove', { uid });
    }
}

export { UserService };
