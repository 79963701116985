import { ReactNode, useState } from 'react';

type AccordionItemProps = {
    title: ReactNode;
    children: ReactNode;
    open?: boolean
};

export function AccordionItem({ title, children, open = false }: AccordionItemProps) {
    const [isOpen, setIsOpen] = useState(open);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="collapse collapse-plus join-item border-base-300 border">
            <input
                type="checkbox"
                checked={isOpen}
                onChange={handleToggle}
            />
            <div className="collapse-title text-xl font-medium" onClick={handleToggle}>
                {title}
            </div>
            <div className="collapse-content">{children}</div>
        </div>
    );
}