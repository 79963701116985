import moment from 'moment';
import 'moment/locale/pt-br';
import CarouselImgs from '../../../../components/files/CarouselImg';
import { Agendamento } from '../../Enum';
import { formatToReal } from '../../../../components/utils';
import { baseURL, urlFile } from '../../../../AxiosConfig';
import ScheduleStatus from '../../estabelecimento/components/ScheduleStatus';

moment.locale('pt-br');

export default function ScheduleView({ schedule }: { schedule: Agendamento }) {
    return (
        <div className="w-full">
            <div className="flex gap-4 mb-6">
                <div className="flex-1 flex flex-col items-center text-center">
                    {schedule?.estab?.imageId ? (
                        <div className="rounded-lg overflow-hidden mt-5 mx-auto">
                            <img src={`${urlFile}/${schedule?.estab?.imageId}`} style={{ width: '120px' }} alt="Logo" />
                        </div>
                    ) : <h2 className="card-title flex items-center gap-2 text-lg font-semibold">
                        {schedule?.estab?.name}
                    </h2>}

                    <p className="text-sm text-gray-600 mt-1">{schedule?.estab?.description}</p>

                    <p className="text-xs text-gray-500 mt-2">
                        {[
                            schedule?.estab?.address?.street,
                            schedule?.estab?.address?.number,
                            schedule?.estab?.address?.complement,
                            schedule?.estab?.address?.neighborhood,
                            schedule?.estab?.address?.city,
                            schedule?.estab?.address?.state,
                            schedule?.estab?.address?.zipCode,
                        ]
                            .filter(Boolean)
                            .join(', ')}
                    </p>

                    {/* Botões */}
                    <div className="flex gap-3 mt-4">
                        {schedule?.estab?.phoneNumber && schedule?.estab?.isPhoneNumberWhatsapp ? (
                            <a
                                href={`https://wa.me/${schedule.estab.phoneNumber.replace(/\D/g, '')}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button
                                    className="btn btn-circle btn-sm flex items-center justify-center text-white bg-green-500 hover:bg-green-600"
                                >
                                    <i className="fa-brands fa-whatsapp text-lg"></i>
                                </button>
                            </a>
                        ) : (
                            <button
                                className="btn btn-circle btn-sm flex items-center justify-center text-white bg-gray-400 cursor-not-allowed"
                                disabled
                            >
                                <i className="fa-brands fa-whatsapp text-lg"></i>
                            </button>
                        )}

                        {schedule?.estab?.address?.addressUrl ? (
                            <a
                                href={schedule.estab.address.addressUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button
                                    className="btn btn-circle btn-sm flex items-center justify-center text-white bg-blue-500 hover:bg-blue-600"
                                >
                                    <i className="fa-solid fa-location-dot text-lg"></i>
                                </button>
                            </a>
                        ) : (
                            <button
                                className="btn btn-circle btn-sm flex items-center justify-center text-white bg-gray-400 cursor-not-allowed"
                                disabled
                            >
                                <i className="fa-solid fa-location-dot text-lg"></i>
                            </button>
                        )}
                    </div>
                </div>
            </div>

            <div className="divider" style={{ padding: 0, margin: 0 }}>
                Serviço
            </div>

            <div className="flex gap-4 flex-wrap justify-center mb-6">
                <div className="w-44 h-44 rounded-lg overflow-hidden">
                    <CarouselImgs imagesIds={schedule.service.imagesIds} autoPlay />
                </div>

                <div className="flex-1">
                    <p className="font-medium mb-2">
                        <strong>Serviço:</strong> {schedule.service.name} - <strong>Preço:</strong>{' '}
                        <span>R$ {formatToReal(schedule.service.price)}</span>
                    </p>

                    <p className="mb-2 text-sm">
                        <strong>Data do Agendamento:</strong>{' '}
                        {moment(schedule.startAt).format('DD/MM/YYYY [às] HH:mm')}
                    </p>

                    {schedule.confirmationDate && (
                        <p className="mb-2 text-sm">
                            <strong>Conf. do Agendamento:</strong>{' '}
                            {moment(schedule.confirmationDate).format('DD/MM/YYYY às HH:mm')}
                        </p>
                    )}

                    <p className="mb-2 text-sm">
                        <strong>Status:</strong>{' '}
                        <ScheduleStatus
                            clientId={schedule.client.uid}
                            key={schedule._id}
                            id={schedule._id}
                            status={schedule.status}
                            size="xs"
                        />
                    </p>

                    {schedule?.confirmation?.date && (
                        <div className="text-xs text-gray-600 flex items-center gap-1">
                            <i className="fa-solid fa-calendar-check text-green-900"></i>
                            Confirmado{' '}
                            <span className="font-medium">{moment(schedule?.confirmation?.date).fromNow()}</span>
                        </div>
                    )}

                    {schedule?.cancellation?.date && (
                        <div className="text-xs text-gray-600 flex items-center gap-1">
                            <i className="fa-solid fa-calendar-xmark text-red-700"></i>
                            Cancelado{' '}
                            <span className="font-medium">{moment(schedule?.cancellation?.date).fromNow()}</span>
                        </div>
                    )}
                </div>
            </div>

            <div className="divider">Atendente</div>
            <div className="grid justify-center mb-4">
                <div className="text-center">
                    <img
                        alt="Foto do Cliente"
                        src={`${baseURL}/users/profileImage/${schedule?.attendant.uid}`}
                        className="h-14 w-14 object-cover mx-auto rounded-full"
                        loading="lazy"
                    />

                    <p className="text-lg font-bold">{schedule.attendant.name}</p>
                    <p className="text-xs">{schedule.attendant.email}</p>
                    {schedule.attendant?.phoneNumber && (
                        <div>
                            <p className="text-xs">{schedule.attendant.phoneNumber}</p>

                            {schedule.attendant.phoneNumber.replace(/\D/g, '') ? (
                                <a
                                    href={`https://wa.me/${schedule.attendant.phoneNumber.replace(/\D/g, '')}`}
                                    className="btn btn-circle btn-sm mt-2 flex items-center justify-center w-12 h-12 bg-green-500 hover:bg-green-600 text-white transition-colors"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fa-brands fa-whatsapp"></i>
                                </a>
                            ) : (
                                <button
                                    className="btn btn-circle btn-sm mt-2 flex items-center justify-center w-12 h-12 bg-gray-400 cursor-not-allowed text-white"
                                    disabled
                                >
                                    <i className="fa-brands fa-whatsapp"></i>
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </div>

            {schedule?.assessment?.value && (
                <div className="text-center">
                    <div className="divider">Avaliação</div>
                    <div>
                        <p>
                            <div className="rating rating-lg">
                                {[...Array(5)].map((_, index) => (
                                    <input
                                        key={index}
                                        type="radio"
                                        name="rating-8"
                                        className="mask mask-star-2 bg-orange-400"
                                        disabled
                                        defaultChecked={index + 1 === schedule?.assessment?.value}
                                    />
                                ))}
                            </div>
                        </p>
                        <p>{schedule?.assessment?.observation}</p>
                    </div>
                </div>
            )}
        </div>
    );
}
