import { useEffect, useState } from 'react';
import moment from 'moment';
import { AtendentesService } from '../../../atendentes/Service';
import { useFormHorarioClienteContext } from './Context';
import { DurationService } from '../../../servicos/list/Duration';
import { Loading } from '../../../../components/Loading';
import { baseURL } from '../../../../AxiosConfig';
import { AgendaClienteService } from '../../cliente/Service';
import { useEstabsContext } from '../../../../contexts/EstabsContext';
import SelectClient from './SelectClient';

const serviceAtendentes = new AtendentesService();
const horarioService = new AgendaClienteService();

export const SelectHorario = () => {
    const { estabSession } = useEstabsContext();

    const [loading, setLoading] = useState({
        atendentes: false,
        horarios: false,
    });
    const [horarios, setHorarios] = useState([] as any);
    const [atendentes, setAtendentes] = useState([] as any);

    const {
        date,
        data,
        handlePreviousWeek,
        handleNextWeek,
        setDate,
        servico,
        horario,
        setData,
        atendente,
        setAtendente,
        setHorario,
        daysOpen,
        loading: loadingForm,
    } = useFormHorarioClienteContext();

    const startOfWeek = moment(date).startOf('week');
    const endOfWeek = moment(date).endOf('week');
    const days = [];
    for (let day = startOfWeek; day <= endOfWeek; day.add(1, 'days')) {
        days.push(day.clone());
    }

    useEffect(() => {
        setData(date.toDate());
        setHorario(0);
    }, [date]);

    const loadAtendentes = async () => {
        try {
            setLoading({ ...loading, atendentes: true });
            if (estabSession._id) {
                const { data } = await serviceAtendentes.getByEstabId(estabSession._id, servico._id);
                setAtendentes(data);
            }
        } catch (err) {
            // Trate o erro se necessário
        } finally {
            setLoading({ ...loading, atendentes: false });
        }
    };

    const loadHorarios = async () => {
        try {
            setLoading({ ...loading, horarios: true });
            if (estabSession?._id && atendente?.uid && servico?._id) {
                const { data } = await horarioService.getHorarios(
                    estabSession._id,
                    date.toDate(),
                    servico._id,
                    atendente.uid,
                );
                setHorarios(data);
            } else {
                setHorarios([]);
            }
        } catch (err) {
            // Trate o erro se necessário
        } finally {
            setLoading({ ...loading, horarios: false });
        }
    };

    useEffect(() => {
        loadAtendentes();
    }, [servico]);

    useEffect(() => {
        loadHorarios();
    }, [atendente, servico, data]);

    const canGoToPreviousDay = () => {
        const previousDay = moment(date).subtract(1, 'day');
        const isPastDay = previousDay.isBefore(moment(), 'day');
        return !isPastDay;
    };

    const canGoToNextDay = () => {
        const hojeInicio = moment().startOf('day');
        const limiteData = hojeInicio.add(daysOpen, 'days');
        const nextDay = moment(date).add(1, 'day');
        const isBeyondDaysOpen = nextDay.isAfter(limiteData, 'day');
        return !isBeyondDaysOpen;
    };

    return (
        <div className="grid justify-center animate__animated animate__fadeInLeft animate__faster">
            {servico?._id && (
                atendentes.length === 0
                    ? <p className="text-center">Nenhum Atendente.</p>
                    : <div className={'space-y-2 py-3'}>
                        <div className="flex items-center justify-center">
                            {loading?.atendentes ? (
                                <>
                                    <div className="skeleton h-20 w-20 shrink-0" style={{ borderRadius: '100%' }}></div>
                                    <div className="skeleton h-20 w-20 shrink-0" style={{ borderRadius: '100%' }}></div>
                                    <div className="skeleton h-20 w-20 shrink-0" style={{ borderRadius: '100%' }}></div>
                                </>
                            ) : (
                                atendentes.map((a: any, index: any) => (
                                    <div
                                        key={index}
                                        className="grid justify-items-center hover:cursor-pointer "
                                        onClick={() => {
                                            setAtendente(a?.uid === atendente?.uid ? '' : a);
                                        }}
                                    >
                                        <div className="avatar">
                                            <div
                                                className={`${
                                                    atendente?.uid === a?.uid ? 'ring-2 ring-black shadow-2xl' : 'mask'
                                                } w-10 rounded-full mb-3`}
                                            >
                                                <img
                                                    src={`${baseURL}/users/profileImage/${a?.uid}`}
                                                    alt="Foto do atendente"
                                                />
                                            </div>
                                        </div>
                                        <div className="text-xs">{a?.displayName}</div>
                                    </div>
                                ))
                            )}
                        </div>

                        {atendente?.uid && (
                            <div className="space-y-4">
                                <div className="divider" style={{ margin: 0 }}></div>
                                <div className="text-center text-xs">
                                    {moment(data).format('dddd, DD [de] MMMM [de] YYYY')}
                                </div>

                                <div className="flex justify-center">
                                    <button
                                        disabled={loadingForm || !canGoToPreviousDay()}
                                        className="btn btn-sm btn-circle mr-2"
                                        onClick={handlePreviousWeek}
                                    >
                                        <i className="fa-solid fa-angle-left"></i>
                                    </button>
                                    <button
                                        disabled={loadingForm || !canGoToNextDay()}
                                        className="btn btn-sm btn-circle ml-2"
                                        onClick={handleNextWeek}
                                    >
                                        <i className="fa-solid fa-angle-right"></i>
                                    </button>
                                </div>

                                <div className="flex items-center gap-1 justify-center">
                                    {days.map((day, index) => {
                                        const isSelected = day.isSame(date, 'day');
                                        const isPastDay = day.isBefore(moment(), 'day');
                                        const hojeInicio = moment().startOf('day');
                                        const limiteData = hojeInicio.add(daysOpen, 'days');
                                        const isBeyondDaysOpen = day.isAfter(limiteData, 'day');

                                        return (
                                            <div
                                                key={index}
                                                onClick={() =>
                                                    !loadingForm && !isPastDay && !isBeyondDaysOpen && setDate(day)
                                                }
                                                style={{ padding: '1px' }}
                                                className={`cursor-pointer text-xs rounded-xl shadow-md text-center w-10 transition-all duration-200 ${
                                                    loadingForm || isPastDay || isBeyondDaysOpen
                                                        ? 'cursor-not-allowed opacity-35'
                                                        : 'hover:shadow-2xl'
                                                } ${
                                                    isSelected && !(loadingForm || isPastDay || isBeyondDaysOpen)
                                                        ? 'brightness-50 shadow-2xl'
                                                        : ''
                                                }`}
                                            >
                                                <div className="bg-primary text-white font-bold rounded-t-xl py-1">
                                                    {day.format('ddd').toUpperCase()}
                                                </div>
                                                <div className="text-gray-800 font-semibold py-1 rounded-b-xl">
                                                    {day.format('DD')}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>

                                {horarios.length === 0 ? (
                                    <p className="text-center w-full">Nenhum horário disponível.</p>
                                ) : (
                                    <div className="flex flex-wrap justify-center gap-2 w-full lg:w-5/12 mx-auto">
                                        {loading?.horarios ? (
                                            <div className="flex justify-center w-full">
                                                <Loading />
                                            </div>
                                        ) : (
                                            horarios.map((h: any, idx: number) => (
                                                <button
                                                    key={idx}
                                                    disabled={loadingForm}
                                                    onClick={() => setHorario(h)}
                                                    className={`btn btn-sm ${h === horario ? 'btn-neutral' : 'btn-outline'}`}
                                                >
                                                    <DurationService duration={h} />
                                                </button>
                                            ))
                                        )}
                                    </div>
                                )}

                                <SelectClient />
                            </div>
                        )}
                    </div>
            )}
        </div>
    );
};
