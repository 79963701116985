import React from 'react';
import { useLandingPageContext } from '../Context';
import { Slider } from 'rsuite';

export const Gallery = () => {
    const { estab } = useLandingPageContext();
    const images = estab?.images || [];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        draggable: true,
    };

    return (
        <section id="gallery" className="py-16">
            <div className="container mx-auto px-4 h-full">
                <div className="flex items-center mb-8">
                    <hr className="flex-grow border-t border-gray-700"/>
                    <h2 className="text-3xl font-bold text-center mx-4 text-white text-shadow">Galeria</h2>
                    <hr className="flex-grow border-t border-gray-700"/>
                </div>

                <div className="flex justify-center items-center">
                    <div className="w-full max-w-4xl relative overflow-hidden h-[600px] rounded-xl shadow-2xl">
                        {/* Usando o Slider do react-slick */}
                        <Slider {...settings}>
                            {images.map((image: any, index: any) => (
                                <div key={index}>
                                    <img
                                        src={image.url}
                                        alt={`Imagem da galeria ${index + 1}`}
                                        className="w-full h-full object-cover"
                                    />
                                    {image.caption && (
                                        <div className="absolute inset-x-0 bottom-0 bg-black bg-opacity-60 text-white p-6 backdrop-blur-sm">
                                            <h3 className="text-2xl font-semibold text-center">
                                                {image.caption}
                                            </h3>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
};
