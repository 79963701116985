import moment from 'moment';
import { useFormHorarioClienteContext } from './Context';
import { convertMinutesToHHMM } from '../../../../components/utils';
import { useEffect } from 'react';
import { brandingEstabId } from '../../../../branding';

export const AgendaFormHeader = () => {
    const {
        estab,
        setEstab,
        servico,
        setServico,
        data,
        setData,
        setAtendente,
        horario,
        setHorario,
        loading,
    } = useFormHorarioClienteContext();

    const getHeaderMessage = () => {
        let message = '';
        if (!estab?._id) {
            message = 'Vamos começar! Selecione um Estabelecimento para dar o primeiro passo.';
        } else if (!servico?._id) {
            message = 'Escolha um Serviço que você deseja.';
        } else if (!horario) {
            message = 'Quase lá! Selecione o Dia, um Atendente e o horário que você gostaria de agendar.';
        } else {
            message = 'Tudo pronto! Basta confirmar seu agendamento e aproveitar o serviço! 😊';
        }
        return (
            <div key={message} className="text-base mb-3 text-center animate__animated animate__fadeIn">
                {message}
            </div>
        );
    };

    useEffect(() => {
        getHeaderMessage();
    }, [estab, servico, horario, data]);

    return (
        <div>
            <div className="flex flex-wrap justify-center breadcrumbs">
                <ul className="flex flex-wrap gap-2 justify-center">
                    {
                        !brandingEstabId && (estab?._id ? (
                            <li className="animate__animated animate__fadeIn">
                                <button
                                    disabled={loading}
                                    className="btn btn-xs p-1 hover:animate__animated hover:animate__bounce"
                                    onClick={() => {
                                        setEstab({});
                                        setServico({});
                                        setData({});
                                        setAtendente({});
                                        setHorario(0);
                                    }}
                                >
                                    {estab?.name}
                                </button>
                            </li>
                        ) : (
                            <li className="animate__animated animate__fadeIn">
                                <button
                                    className="btn btn-xs btn-ghost p-1 hover:animate__animated hover:animate__bounce">
                                    Estabelecimento
                                </button>
                            </li>
                        ))
                    }

                    {servico?._id ? (
                        <li className="animate__animated animate__fadeIn">
                            <button
                                disabled={loading}
                                className="btn btn-xs p-1 hover:animate__animated hover:animate__bounce"
                                onClick={() => {
                                    setServico({});
                                    setData({});
                                    setAtendente({});
                                    setHorario(0);
                                }}
                            >
                                {servico?.name}
                            </button>
                        </li>
                    ) : (
                        estab?._id && (
                            <li className="animate__animated animate__fadeIn">
                                <button
                                    className="btn btn-xs btn-ghost p-1 hover:animate__animated hover:animate__bounce">
                                    Serviço
                                </button>
                            </li>
                        )
                    )}

                    {!!estab?._id && !!servico?._id && !!horario && !!data ? (
                        <li className="animate__animated animate__fadeIn">
                            <button
                                disabled={loading}
                                className="btn btn-xs p-1 hover:animate__animated hover:animate__bounce"
                                onClick={() => {
                                    setData(new Date());
                                    setAtendente({});
                                    setHorario(0);
                                }}
                            >
                                {moment(data).format('DD/MM/YYYY')} às {convertMinutesToHHMM(horario)}
                            </button>
                        </li>
                    ) : (
                        servico?._id && (
                            <li className="animate__animated animate__fadeIn">
                                <button
                                    className="btn btn-xs btn-ghost p-1 hover:animate__animated hover:animate__bounce">
                                    Horário
                                </button>
                            </li>
                        )
                    )}
                </ul>
            </div>
            {getHeaderMessage()}
        </div>
    );
};
