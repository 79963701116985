interface LoadingProps {
    size?: 'xs' | 'sm' | 'md' | 'lg';
}

export const Loading = ({ size = 'md' }: LoadingProps) => {
    const sizeClasses = {
        xs: 'h-4 w-4 border-2',
        sm: 'h-6 w-6 border-3',
        md: 'h-8 w-8 border-4',
        lg: 'h-12 w-12 border-4',
    };

    return (
        <div
            className={`inline-block animate-spin rounded-full border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white ${sizeClasses[size]}`}
            role="status"
        ></div>
    );
};
