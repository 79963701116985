import { DateItem } from './Item';
import SelectAtendentes from '../../../../components/inputs/SelectAtendentes';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useListAgendaContext } from './Context';
import { useEffect, useState } from 'react';
import { AtendentesService } from '../../../atendentes/Service';
import { Loading } from '../../../../components/Loading';
import { WebSocketManager } from '../../../../WebSocketConfig';
import { useEstabsContext } from '../../../../contexts/EstabsContext';
import { AgendaProvider } from '../ContextAgenda';

const atendentesService = new AtendentesService();

export interface AgendaEstabelecimentoDate {
    date: string;
    total: number;
    agora: {
        horario: string;
        servico: string;
    };
    proximo: {
        horario: string;
        servico: string;
    };
}

export const AgendaEstabelecimentoList = () => {
    const { user } = useAuthContext();
    const { estabSession } = useEstabsContext();
    const [loading, setLoading] = useState(false);
    const { setAttendantId, attendantId } = useListAgendaContext();
    const [agenda, setAgenda] = useState<AgendaEstabelecimentoDate[]>([]);

    const loadAgenda = async () => {
        if (loading) return;

        try {
            setLoading(true);

            const { data } = await atendentesService.getAgendaList(attendantId);

            setAgenda(data);
        } catch (e) {
            console.error('Erro ao carregar a agenda:', e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!user?.uid || !estabSession?._id) return;

        const webSocketManager = new WebSocketManager();
        const handleNotification = async (message: any) => {
            const { data } = await atendentesService.getAgendaList(attendantId);

            setAgenda(data);
        };

        setTimeout(function() {
            if (webSocketManager.socket) {
                webSocketManager.onChannel(`horarios:atendentes:${estabSession._id}`, handleNotification);
            }
        }, 1000);

        return () => {
            webSocketManager.disconnect();
        };
    }, [attendantId, estabSession, user]);

    useEffect(() => {
        loadAgenda();
    }, [attendantId]);

    return (
        <>
            {(user?.admin || user?.root) && (
                <div className="mb-3">
                    <SelectAtendentes
                        value={attendantId}
                        setValue={setAttendantId}
                        name="atendente"
                        placeholder="Meus agendamentos"
                        multiple={false}
                    />
                    <div className="divider"></div>
                </div>
            )}
            <div className="pb-14 space-y-2">
                {loading ?
                    <div className="flex justify-center mt-5"><Loading /></div> : agenda.map((agendaItem, index) => (
                        <AgendaProvider date={agendaItem.date}>
                            <DateItem key={index} agenda={agendaItem} index={index} />
                        </AgendaProvider>
                    ))}
            </div>
        </>
    );
};
