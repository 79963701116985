import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { UserService } from '../Service';
import moment from 'moment';
import PhoneNumber from '../../../../components/inputs/PhoneNumber';
import { VerificationCodeService } from '../../../verificationCode/service';

const service = new UserService();
const verificationCodeService = new VerificationCodeService();

export default function ConfiguracoesContaPhoneNumber() {
    const { user, changeUser } = useAuthContext();
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState<any>(null);
    const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || '');

    useEffect(() => {
        setLoading(true);
        verificationCodeService
            .getByAction('changePhoneNumber')
            .then(({ data }) => {
                console.log(data);

                setRequest(data);
            })
            .catch(() => {
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!!phoneNumber) {
            setLoading(true);
            try {
                const { data } = await service.requestChangePhoneNumber(phoneNumber);
                setRequest(data);
                setPhoneNumber('');
            } catch (e) {
            } finally {
                setLoading(false);
            }
        }
    };

    const removeRequest = async () => {
        if (!request?._id) return;

        setLoading(true);
        try {
            await verificationCodeService.delete(request?._id);
            setRequest(null);
        } catch (e) {
        } finally {
            setLoading(false);
        }
    };

    const removeNumber = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setLoading(true);
        try {
            await service.removePhoneNumber();
            setPhoneNumber('');
            setRequest(null);
            changeUser({phoneNumber: ''})
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    if (request) {
        return (
            <div className="alert text-sm alert-info">
                <div>
                    Solicitação enviada para o número:
                    <b> +{request?.data?.phoneNumber || 'desconhecido'}</b>.
                    <br />
                    O código expira em <b>{moment(request.expiresIn).format('DD/MM/YYYY HH:mm')}</b>.
                    <br />
                    <button className="btn btn-sm mt-3" onClick={removeRequest} disabled={loading}>
                        Remover solicitação
                    </button>
                </div>
            </div>
        );
    }

    if (!!user?.phoneNumber) {
        return (
            <div className="form-control mb-4">
                <form onSubmit={removeNumber}>
                    <div className="flex items-center gap-2">
                        <i className="fa-solid fa-circle-check text-green-500" />
                        <input
                            id="phone"
                            className="input input-bordered maska"
                            data-maska="['+55 (##) ####-####', '+55 (##) # ####-####']"
                            value={user?.phoneNumber}
                            disabled={true}
                        />
                    </div>
                    <div className="divider" />
                    <button className="btn btn-error" type="submit" disabled={loading}>
                        Remover
                    </button>
                </form>
            </div>
        );
    }

    return (
        <div className="form-control mb-4">
            <form onSubmit={handleSubmit}>
                <PhoneNumber value={phoneNumber} setValue={setPhoneNumber} label="Número de telefone" />
                <div className="divider" />

                <button className="btn" type="submit" disabled={loading}>
                    Continuar
                </button>
            </form>
        </div>
    );
}
